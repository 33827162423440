import moment from 'moment'
import React from 'react'
import _ from 'lodash'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

const labelMap = {
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly'
}

const LineCharts = (props) => {
  const { growthData, userPeriod, setGrowthAvg, growthAvg } = props
  const getFormattedData = () => {
    if (userPeriod === 'WEEKLY') {
      return growthData
        .sort((a, b) => new Date(a.week) - new Date(b.week))
        .map((item) => ({ count: item.count, year: moment(item.week).format('DD MMM') }))
    } else if (userPeriod === 'MONTHLY') {
      return growthData
        .sort((a, b) => new Date(a.month) - new Date(b.month))
        .map((item) => ({ count: item.count, year: moment(item.month).format('MMM') }))
    } else {
      return growthData
        .sort((a, b) => new Date(a.year) - new Date(b.year))
        .map((item) => ({ count: item.count, year: moment(item.year).format('YYYY') }))
    }
  }
  const calculateAvg = (e, data) => {
    let value = 0
    if (data.index > 0) {
      value = _.round(
        ((growthData[data.index].count - growthData[data.index - 1].count) / growthData[data.index - 1].count) * 100
      )
    }
    setGrowthAvg(value)
  }
  const getMax = () => {
    let max = 15
    growthData.map((item) => {
      if (item.count > max) {
        max = +item.count
      }
      return null
    })
    return max
  }
  return (
    <div className='graph_wrap'>
      <div className='graph_title'>
        <h2>User Growth ({labelMap[userPeriod] || 'Yearly'})</h2>
        <div>Avg Growth rate: {growthAvg}%</div>
      </div>
      <LineChart
        width={800}
        height={300}
        data={getFormattedData()}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='year' />
        <YAxis
          dataKey='count'
          domain={[0, getMax() + 50]}
        />
        <Tooltip />
        <Line
          type='monotone'
          dataKey='count'
          stroke='#8884d8'
          activeDot={{
            r: 8,
            onClick: (e, data) => {
              calculateAvg(e, data)
            }
          }}
        />
      </LineChart>
    </div>
  )
}
export default LineCharts
