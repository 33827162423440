import { serviceHandler } from '../../utils/serviceHandler'
import apiEndPoints from '../../utils/apiUrlConstants'

const { User } = apiEndPoints

export const adminLogin = (payload) => {
  return serviceHandler({
    url: `/admin/auth/login`,
    methodType: 'post',
    payload
  })
}

export const verifyOtp = (payload) => {
  return serviceHandler({
    url: `/admin/auth/otp`,
    methodType: 'post',
    payload
  })
}
