import { serviceHandler } from '../../utils/serviceHandler'
import apiEndPoints from '../../utils/apiUrlConstants'

const { User } = apiEndPoints

export const getProfiles = (page, search = '', sort) => {
  return serviceHandler({
    url: `/verification_photo/admin`,
    methodType: 'get',
    params: {
      page,
      // searchKey:"first_name",
      // searchValue:search,
      sortKey: 'created_on',
      sortOrder: sort ? sort : 'desc'
    }
  })
}
