import { serviceHandler } from '../../utils/serviceHandler'
import apiEndPoints from '../../utils/apiUrlConstants'

const { User } = apiEndPoints

/**
 *
 * @deprecated To be replaced with searchUsers but currently used in multiple other places
 */
export const users = (page = 1, value = '', sort = 'desc', sortKey, limit = 20) => {
  return serviceHandler({
    url: `${User}`,
    methodType: 'get',

    params: {
      page: page,
      searchKey: 'name',
      searchValue: value,
      sortKey,
      sortOrder: sort ? sort : 'desc',
      limit
    }
  })
}

/**
 * Search for paginated list of users
 *
 * @param page {number}
 * @param limit {number}
 * @param first_name {string|undefined}
 * @param last_name {string|undefined}
 * @param email {string|undefined}
 * @returns {Promise<{data: *, success: boolean} | {data: *, success: boolean}> | *}
 */
export const searchForUsers = (
  page = 1,
  limit = 20,
  first_name = undefined,
  last_name = undefined,
  email = undefined
) => {
  return serviceHandler({
    url: `${User}/search`,
    methodType: 'get',
    params: {
      page,
      limit,
      first_name,
      last_name,
      email
    }
  })
}

export const exportSearchedUsers = (
  page = 1,
  limit = 20,
  first_name = undefined,
  last_name = undefined,
  email = undefined
) => {
  return serviceHandler({
    url: `${User}/export_search`,
    methodType: 'get',
    params: {
      page,
      limit,
      first_name,
      last_name,
      email
    }
  })
}

export const userDataDetails = (id) => {
  return serviceHandler({
    url: `${User}/${id}`,
    methodType: 'get'
  })
}

export const userExport = (id) => {
  return serviceHandler({
    url: `admin/export/users`,
    methodType: 'get'
  })
}

export const userDeActivate = (id) => {
  return serviceHandler({
    url: `user/delete-account/${id}`,
    methodType: 'delete'
  })
}

export const userActivate = (id) => {
  return serviceHandler({
    url: `user/activate-account/${id}`,
    methodType: 'put'
  })
}

export const userMarkPending = (id) => {
  return serviceHandler({
    url: `user/pending-account/${id}`,
    methodType: 'put'
  })
}

export const userApprove = (id) => {
  return serviceHandler({
    url: `verification_requests/${id}/approve`,
    methodType: 'put'
  })
}

export const userReject = (id) => {
  return serviceHandler({
    url: `verification_requests/${id}/reject`,
    methodType: 'put'
  })
}

export const getOtpUser = (id) => {
  return serviceHandler({
    url: `admin/otp`,
    methodType: 'get',
    params: { user_id: id }
  })
}

export const editUser = (id, payload) => {
  return serviceHandler({
    url: `admin/user/${id}`,
    methodType: 'put',
    payload
  })
}
