export const action = [
  {
    count: '27,300',
    key: 'matches',
    label: 'Matches'
  },
  {
    count: '55,234',
    key: 'likes',
    label: 'Likes'
  },
  {
    count: '1.5M',
    key: 'messages',
    label: 'Messages'
  },
  {
    count: '674',
    key: 'verifiedUsers',
    label: 'Users Verified'
  }
]
export const user = [
  {
    count: '14,915',
    key: 'users',
    label: 'Total Users'
  },
  {
    count: '4,510',
    key: 'activeUsers',
    label: 'Active Users'
  },
  {
    count: '4,510',
    key: 'pendingUsers',
    label: 'Pending Users'
  },
  {
    count: '268',
    key: 'inactiveUsers',
    label: 'Deactivated Users'
  }
]
export const dropDownOption = [
  {
    key: '1',
    label: 'Weekly'
  },
  {
    key: '2',
    label: 'Monthly'
  },
  {
    key: '3',
    label: 'Yearly'
  }
]
