import './App.scss'
import AdminDashboard from './pages/AdminDashboard/AdminDashboard'
import { Layout } from 'antd'
import 'antd/dist/antd.min.css'
import 'antd/dist/antd.less'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './pages/Login/Login'

const { Content } = Layout

function App() {
  return (
    <Layout className='events-app-layout'>
      <Content className='app-content'>
        <Router>
          <Routes>
            <Route
              path='/'
              element={<Login />}
            />

            <Route
              path='/admin/*'
              element={<AdminDashboard />}
            />
          </Routes>
        </Router>
      </Content>
    </Layout>
  )
}

export default App
