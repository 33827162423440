import React, { useEffect, useState } from 'react'
import { Collapse, Space } from 'antd'
// import { activityFields } from "./constants";

const { Panel } = Collapse

const BasicCard = ({ userDetails }) => {
  const [activityFields, setActivityField] = useState([])
  useEffect(() => {
    if (userDetails) {
      const activityField = [
        {
          label: 'Age:',
          value: userDetails.UserInfo?.age
        },
        {
          label: 'Job:',
          value: userDetails.UserInfo?.occupation
        },
        {
          label: 'Instagram ID:',
          value: userDetails.UserInfo?.instagram_id
        },
        {
          label: 'School:',
          value: userDetails.UserInfo?.school
        },
        {
          label: 'Pronoun:',
          value: userDetails.UserInfo?.pronoun_option?.label
        },
        {
          label: 'Education:',
          value: userDetails.UserInfo?.education_option?.label
        },
        {
          label: 'Pollitical View:',
          value: userDetails.UserInfo?.politicalview_option?.label
        },
        {
          label: 'Religion:',
          value: userDetails.UserInfo?.religion_option?.label
        },
        {
          label: 'Vaccination Status:',
          value: userDetails.UserInfo?.vaccinated_status_option?.label
        },
        {
          label: 'Children Preference:',
          value: userDetails.UserInfo?.children_preference?.label
        }
      ]
      setActivityField(activityField)
    }
  }, [userDetails])
  return (
    <div className='user_accord'>
      <Space direction='vertical'>
        <Collapse
          collapsible='header'
          defaultActiveKey={['1']}
          expandIconPosition='end'
          size='large'
        >
          <Panel
            header='Basic Details'
            key='1'
          >
            <div className='act_details'>
              {activityFields.map((item, i) => {
                return (
                  <div
                    className='activity_list'
                    key={`basic-field-${i}`}
                  >
                    <label>{item.label}</label>
                    <div className='value'>{item.value}</div>
                  </div>
                )
              })}
            </div>
          </Panel>
        </Collapse>
      </Space>
    </div>
  )
}
export default BasicCard
