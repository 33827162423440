import userIcon from '../assets/user-icon.svg'
import ProfileMenu from '../assets/profile-menu.svg'
import Abuse from '../assets/abuse.svg'
import dashboard from '../assets/dashbrd.svg'
import Dynamic from '../assets/dynamic.svg'
import Contact from '../assets/contact.svg'
import Message from '../assets/message.svg'
import Announce from '../assets/anounce_icon.svg'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const storeCookies = (name, value) => {
  cookies.set(name, JSON.stringify(value), { path: '/' })
}

export const getCookies = (name) => {
  return cookies.get(name)
}
export const removeCookies = (name) => {
  return cookies.remove(name)
}
export const adminSideMenuLinks = [
  {
    name: 'Dashboard',
    link: '/admin/dashboard',
    icon: dashboard
  },
  {
    name: 'User Management',
    link: '/admin/user-management',
    icon: userIcon
  },
  {
    name: 'Pending Accounts',
    link: '/admin/pending-accounts',
    icon: userIcon
  },
  {
    name: 'Profile Verification',
    link: '/admin/profile-verification',
    icon: ProfileMenu
  },
  {
    name: 'Abuse Management',
    link: '/admin/abuse-management',
    icon: Abuse
  },
  {
    name: 'Dynamic Content',
    link: '/admin/dynamic-content',
    icon: Dynamic
  },
  {
    name: 'Contact us Management',
    link: '/admin/contact-us-management',
    icon: Contact
  },
  {
    name: 'Messaging',
    link: '/admin/messaging',
    icon: Message
  },
  {
    name: 'Announcement',
    link: '/admin/announcement',
    icon: Announce
  }
]
