import { Table } from 'antd'
import React, { useState } from 'react'

const DeetsTable = (props) => {
  const { deetsList } = props
  const [viewCount, setViewCount] = useState(10)

  const updateView = () => {
    if (viewCount === 10) {
      setViewCount(deetsList.length)
    } else {
      setViewCount(10)
    }
  }

  const columns = [
    {
      title: 'Disability Deet',
      dataIndex: 'deet',
      width: '60%',
      key: 'deet'
    },
    {
      title: 'Number of Users',
      dataIndex: 'count',
      key: 'count'
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      render(percent) {
        return percent + '%'
      }
    }
  ]
  return (
    <div className='dash-table'>
      <h3>Dateability Deets Breakdown</h3>
      <Table
        dataSource={deetsList.slice(0, viewCount)}
        columns={columns}
        pagination={false}
        size='small'
        bordered
      />
      <div
        className='view_more'
        onClick={() => {
          updateView()
        }}
      >
        {viewCount === 10 ? 'See all' : 'Less'}
      </div>
    </div>
  )
}
export default DeetsTable
