import React from 'react'
import moment from 'moment'

const ActivityCard = ({ userDetails }) => {
  const getOpt = (value, type) => {
    switch (type) {
      case '1':
        return 'Text'
      case '2':
        return 'Push'
      case '3':
        return 'Email'
      default:
        return ''
    }
  }

  const activityFields = [
    {
      label: 'Active Status:',
      value: userDetails.account_status
    },
    {
      label: 'Sign up Date:',
      value: userDetails && userDetails.created_on ? moment(userDetails.created_on).format('DD MMM, YYYY') : null
    },
    {
      label: 'Opt-in:',
      value: [
        getOpt(userDetails.UserSettings?.text_notifications, '1'),
        getOpt(userDetails.UserSettings?.push_notifications, '2'),
        getOpt(userDetails.UserSettings?.email_notifications, '3')
      ].join(', ')
    },
    {
      label: 'Last Activity Date:',
      value:
        userDetails && userDetails.last_login_time ? moment(userDetails.last_login_time).format('DD MMM, YYYY') : null
    },
    {
      label: 'Reported on Date:',
      value: userDetails.reported_date
    },
    {
      label: 'Matches:',
      value: userDetails.total_matches
    },
    {
      label: 'Likes Given:',
      value: userDetails.total_likes_given
    },
    {
      label: 'Likes Received:',
      value: userDetails.total_likes_received
    }
  ]
  return (
    <div className='card'>
      <div className='header main_head'>
        <h3> Activity Details</h3>
      </div>
      <div className='content'>
        <div className='act_details'>
          {activityFields.map((item, i) => {
            return (
              <div
                className='activity_list'
                key={`activity-item-${i}`}
              >
                <label>{item.label}</label>
                <div className='value'>{item.value}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default ActivityCard
