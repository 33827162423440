import React, { useState, useCallback } from 'react'
import { Button, Input, Modal, Select, message } from 'antd'

import { rejectPendingUser, approvePendingUser } from './services'

const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

export const AccountReviewButtons = ({ userDetail, onComplete, ...props }) => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false)
  const [accountStatusReason, setAccountStatusReason] = useState(null)
  const [accountStatusReasonMessage, setAccountStatusReasonMessage] = useState(null)

  const openRejectModal = useCallback(() => {
    setIsRejectModalOpen(true)
  }, [])

  const closeRejectModal = useCallback(() => {
    setIsRejectModalOpen(false)
  }, [])

  const openApprovalModal = useCallback(() => {
    setIsApprovalModalOpen(true)
  }, [])

  const closeApprovalModal = useCallback(() => {
    setIsApprovalModalOpen(false)
  }, [])

  const handleRejectSubmission = useCallback(async () => {
    if (!accountStatusReason) {
      message.error('Please select a reason for rejecting the account')
      return
    }

    if (accountStatusReason === 'OTHER' && !accountStatusReasonMessage) {
      message.error('Please expand on why you chose "other"')
      return
    }

    const result = await rejectPendingUser(userDetail.id, {
      account_status_reason: accountStatusReason,
      account_status_reason_message: accountStatusReasonMessage || userDetail.account_status_reason_message
    })

    if (result?.success) {
      message.success(result?.data?.msg || 'Account Rejected')
      setIsRejectModalOpen(false)
      onComplete && onComplete()
    } else {
      message.error(result?.data?.msg || 'Error rejecting account')
    }
  }, [
    accountStatusReason,
    accountStatusReasonMessage,
    onComplete,
    userDetail.account_status_reason_message,
    userDetail.id
  ])

  const handleApprovalSubmission = useCallback(async () => {
    const result = await approvePendingUser(userDetail.id)
    message.success(result?.data?.msg || 'Account Approved')
    onComplete && onComplete()
    setIsApprovalModalOpen(false)
  }, [onComplete, userDetail.id])

  return (
    <div {...props}>
      <Button
        type='primary'
        danger={false}
        className='delete-btn'
        disabled={userDetail.account_status === 'ACTIVE' || userDetail.account_status === 'DELETED'}
        onClick={openApprovalModal}
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        Approve Account
      </Button>
      <Button
        type='link'
        danger={true}
        disabled={userDetail.account_status === 'REJECTED'}
        onClick={openRejectModal}
        className='delete-btn'
      >
        Reject Account
      </Button>

      <Modal
        title='Reject Account'
        open={isRejectModalOpen}
        okText='Complete Account Rejection'
        okButtonProps={{
          danger: true
        }}
        cancelText='Cancel'
        onOk={handleRejectSubmission}
        onCancel={closeRejectModal}
      >
        <p style={{ fontWeight: 'bold', margin: 0 }}>Name:</p>
        <p>{`${userDetail.first_name} ${userDetail.last_name}`}</p>

        <div className='form-field'>
          <label className='form-label'>Account Rejection Reason</label>
          <Select
            showSearch
            placeholder='Select a reason for rejecting the account'
            optionFilterProp='account_status_reason'
            onSearch={filterOption}
            onChange={setAccountStatusReason}
            size='large'
            filterOption={filterOption}
            style={{ width: '100%' }}
            options={[
              {
                value: 'SPAM',
                label: 'Spam Account'
              },
              {
                value: 'ABUSE',
                label: 'Abuse/Harassment'
              },
              {
                value: 'TOS_VIOLATION',
                label: 'Terms of Service Violation'
              },
              {
                value: 'USER_REQUEST',
                label: 'User Requested'
              },
              {
                value: 'OTHER',
                label: 'Other'
              }
            ]}
          />
        </div>

        <div className='form-field'>
          <label className='form-label'>Other Reason / Additional Explanation</label>
          <Input
            size='large'
            onChange={(e) => setAccountStatusReasonMessage(e.target.value)}
            placeholder='Enter a custom reason for rejecting the account'
            value={userDetail.account_status_reason_message}
          />
        </div>
      </Modal>
      <Modal
        title='Approve Account'
        open={isApprovalModalOpen}
        okText='Complete Account Approval'
        okButtonProps={{
          danger: true
        }}
        cancelText='Cancel'
        onOk={handleApprovalSubmission}
        onCancel={closeApprovalModal}
      >
        <p style={{ fontWeight: 'bold', margin: 0 }}>Name:</p>
        <p>{`${userDetail.first_name} ${userDetail.last_name}`}</p>

        <p>
          <strong>Pending Likes:</strong> {userDetail.total_likes_given}
        </p>
        <p>
          <strong>Pending Matches:</strong> {userDetail.total_matches}
        </p>

        <p style={{ fontStyle: 'italic' }}>
          Upon approval, all pending "likes" and "matches" will become active and all related emails/notifications will
          be sent.
        </p>
      </Modal>
    </div>
  )
}

export default AccountReviewButtons
