import React, { useEffect, useState, useCallback } from 'react'
import { Avatar, Input, Button, message } from 'antd'
import { getOtpUser, editUser } from '../../UserManagement/services'
import Spinner from '../../../components/spinner/Spinner'
import badge from '../../../assets/badge.png'
import { isEmpty, trim } from 'lodash'

import { getUrlForImage } from '../../../utils/imageHelper'

const AccountCard = ({ userDetails }) => {
  const [accountFields, setAccountFields] = useState([])
  const [loading, setLoading] = useState(false)
  const [showEmailOtp, setShowEmailOtp] = useState(false)
  const [allOtp, setOtp] = useState()
  const [isEditingName, setIsEditingName] = useState(false)
  const [userNameState, setUserNameState] = React.useState({
    first_name: userDetails.first_name,
    last_name: userDetails.last_name
  })
  const handleNameChange = (e) => {
    const { name, value } = e.target
    setUserNameState({ ...userNameState, [name]: value })
  }

  useEffect(() => {
    if (userDetails) {
      const accountField = [
        {
          label: 'ID',
          value: userDetails.id,
          type: 'id'
        },
        {
          label: 'Email:',
          value: userDetails.email
        },
        {
          label: 'Gender:',
          value: userDetails.UserInfo?.gender
        },
        {
          label: 'Date of Birth::',
          value: userDetails.UserInfo?.age
        },
        {
          label: 'Bio:',
          value: userDetails.UserInfo?.bio
        }
      ]
      setAccountFields(accountField)
      getOtp(userDetails.id)
    }
  }, [userDetails])

  const getOtp = async (id) => {
    const Data = await getOtpUser(id)
    setOtp(Data?.data?.data?.result)
  }

  const submitNameChange = useCallback(async () => {
    if (isEmpty(trim(userNameState.first_name)) || isEmpty(trim(userNameState.last_name))) {
      message.error('Please enter a first and last name')
      return
    }
    try {
      await editUser(userDetails.id, {
        first_name: trim(userNameState.first_name),
        last_name: trim(userNameState.last_name)
      })
    } catch {
      message.error('Something went wrong')
    }
    userDetails.first_name = trim(userNameState.first_name)
    userDetails.last_name = trim(userNameState.last_name)
    setIsEditingName(false)
  }, [userNameState.first_name, userNameState.last_name, userDetails])

  const cancelNameChange = useCallback(() => {
    setIsEditingName(false)
    setUserNameState({
      first_name: userDetails.first_name,
      last_name: userDetails.last_name
    })
  }, [userDetails])

  const isDev = () => {
    if (process.env.REACT_APP_HOST.indexOf('localhost') !== -1 || process.env.REACT_APP_HOST.indexOf('dev') !== -1)
      return true
    else return false
  }
  return (
    <div className='card'>
      <div className='header main_head'>
        <h3>Account Detail</h3>
        <div style={{ display: 'flex' }}>
          {showEmailOtp ? (
            <div className='show-otp'>
              {allOtp.email_verification_code ? allOtp.email_verification_code : 'Not found'}
            </div>
          ) : (
            <button
              style={{ marginRight: '15px' }}
              onClick={() => setShowEmailOtp(true)}
            >
              Show Email OTP
            </button>
          )}
        </div>
      </div>
      <div className='content'>
        <div className='img_sec'>
          <Avatar
            size={70}
            src={
              userDetails?.UserPhotos?.length > 0 && getUrlForImage(userDetails.UserPhotos[0].photo_url, { width: 70 })
            }
            alt=''
          />
        </div>
        <div className='details'>
          <div
            className='field_list'
            key={`account-field-name`}
          >
            <label>Name</label>
            <div className='value'>
              {!isEditingName && (
                <div>
                  <span>
                    {userDetails.first_name} {userDetails.last_name}
                  </span>
                  {userDetails.is_verified && (
                    <span>
                      <img
                        src={badge}
                        alt='badge'
                        style={{ height: 22, marginLeft: 10 }}
                      />
                    </span>
                  )}

                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => setIsEditingName(true)}
                  >
                    edit
                  </Button>
                </div>
              )}

              {isEditingName && (
                <div style={{ display: 'flex' }}>
                  <Input
                    type='text'
                    name='first_name'
                    onChange={handleNameChange}
                    value={userNameState.first_name}
                  />
                  <Input
                    type='text'
                    name='last_name'
                    onChange={handleNameChange}
                    value={userNameState.last_name}
                  />
                  <Button
                    disabled={
                      userNameState.first_name === userDetails.first_name &&
                      userNameState.last_name === userDetails.last_name
                    }
                    onClick={submitNameChange}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={cancelNameChange}
                    type='text'
                    danger
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </div>
          {accountFields.map((item, i) => {
            return (
              <div
                className='field_list'
                key={`account-field-${i}`}
              >
                <label>{item.label}</label>
                <div className='value'>
                  <span>{item.value}</span>
                  {item.type === 'name' && userDetails.is_verified && (
                    <span>
                      <img
                        src={badge}
                        alt='badge'
                        style={{ height: 22, marginLeft: 10 }}
                      />
                    </span>
                  )}
                  {item.type === 'id' && (
                    <a
                      style={{ marginLeft: 10 }}
                      target='_blank'
                      rel='noopener noreferrer'
                      href={
                        isDev()
                          ? `https://dev.dateabilityapp.com/home/user/${item.value}`
                          : `https://www.dateabilityapp.com/home/user/${item.value}`
                      }
                    >
                      Go to Profile
                    </a>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  )
}
export default AccountCard
