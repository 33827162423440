import React from 'react'
import { Layout, Card, Avatar, Button, Row, Col } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import AccountReviewButtons from './AccountReviewButtons'
import { UserStatusTag } from '../../components/UserStatusTag'

import './PendingAccounts.scss'

import { getUrlForImage } from '../../utils/imageHelper'

const PendingAccountDetails = function ({ detailData, goBack, onComplete }) {
  const navigate = useNavigate()

  return (
    <Layout className='abuse-details'>
      {detailData && (
        <>
          <Row justify='space-between'>
            <Col style={{ display: 'flex' }}>
              {' '}
              <ArrowLeftOutlined
                className='back-arrow'
                onClick={() => goBack(false)}
              />
              <div>
                <div className='profile-subhead'>Name</div>
                <div className='profile-head'>{detailData?.first_name + ' ' + detailData?.last_name}</div>
              </div>
            </Col>

            <Col style={{ display: 'flex' }}>
              <AccountReviewButtons
                key={detailData.id}
                userDetail={detailData}
                style={{ display: 'flex' }}
                onComplete={onComplete}
              />
              <Button
                className='abuse-btn message-btn'
                onClick={() => navigate(`/admin/messaging?search=${detailData?.first_name}`)}
              >
                Message User
              </Button>
            </Col>
          </Row>

          <Card
            className='abused-card'
            title={<div className='card-title'>{detailData?.first_name + ' ' + detailData?.last_name}</div>}
            bordered={false}
            style={{ width: 300 }}
          >
            <Row>
              <Col>
                <Avatar
                  size={60}
                  src={
                    detailData?.UserPhotos?.length > 0
                      ? getUrlForImage(detailData?.UserPhotos[0].photo_url, { width: 60 })
                      : '/sample_img.png'
                  }
                />
              </Col>
              <Col className='abused-name-section'>
                <div className='profile-item'>
                  <div className='title'>Name:</div>
                  <div className='title-value'>
                    <a
                      title='View Profile'
                      href={`/admin/user-details/${detailData.id}`}
                      onClick={() => navigate(`/admin/user-details/${detailData.id}`)}
                    >
                      {detailData?.first_name + ' ' + detailData?.last_name}{' '}
                    </a>
                  </div>
                </div>
                <div className='profile-item'>
                  <div className='title'>Account Status:</div>
                  <div className='title-value'>
                    <UserStatusTag status={detailData.account_status} />
                  </div>
                </div>
                <div className='profile-item'>
                  <div className='title'>Email:</div>
                  <div className='title-value'>{detailData.email}</div>
                </div>
                <div className='profile-item'>
                  <div className='title'>Likes Given:</div>
                  <div className='title-value'>{detailData.total_likes_given}</div>
                </div>
                <div className='profile-item'>
                  <div className='title'>Matches:</div>
                  <div className='title-value'>{detailData.total_matches}</div>
                </div>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </Layout>
  )
}
export default PendingAccountDetails
