import { Modal, Button } from 'antd'
import EditorDesc from './editor'

const ContentEdit = ({
  isModalVisible,
  setIsModalVisible,
  htmlFile,
  setSeletedContent,
  onUpdatecontent,
  seletedContent,
  selectedType
}) => {
  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setSeletedContent({})
  }
  const onTitleChnage = (e) => {
    const currentData = { ...seletedContent }
    currentData[selectedType === 'FAQ' ? 'question' : 'title'] = e.target.value
    setSeletedContent(currentData)
  }
  const handleChangeDesc = (html) => {
    const currentData = { ...seletedContent }
    currentData[selectedType === 'FAQ' ? 'answer' : 'description'] = html
    setSeletedContent(currentData)
  }

  return (
    <Modal
      title={selectedType === 'FAQ' ? 'Update FAQ' : 'Update Resources'}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className='add-modal'
      okText='Done'
      footer={false}
    >
      <div>
        <input
          placeholder='Title'
          className='anounce_title'
          onChange={(e) => {
            onTitleChnage(e)
          }}
          value={selectedType === 'FAQ' ? seletedContent.question : seletedContent.title}
        />
      </div>
      <div className='msg_wrap'>
        <EditorDesc
          formName='desc'
          htmlFile={selectedType === 'FAQ' ? seletedContent.answer : seletedContent.description}
          handleChangeDesc={handleChangeDesc}
        />
      </div>
      <Button
        type='primary'
        htmlType='submit'
        className='button-login'
        onClick={onUpdatecontent}
      >
        Send
      </Button>
    </Modal>
  )
}
export default ContentEdit
