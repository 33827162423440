import React from 'react'
import moment from 'moment'
import DeleteIcon from '../../../assets/delete.svg'
import logo from '../../../assets/announce.png'
import ViewDesc from './ViewDesc'

const Card = (props) => {
  const { list, onDelete } = props

  return (
    <div className='card-wrap'>
      <div className='image'>
        <img
          src={logo}
          alt=''
        />
      </div>
      <div className='contents'>
        <div className='content-head'>
          <div>
            <label>Date Created</label>
            <div>{moment(list?.notification_time).format('DD MMM, YYYY')}</div>
          </div>
          {/* <div>
                    <label>Date Created</label>
                    <div>18 February 2023</div>
                </div> */}
          <div>
            <img
              src={DeleteIcon}
              alt=''
              className='action-icon'
              onClick={() => {
                onDelete(list)
              }}
            />
          </div>
        </div>
        <div className='content-title'>
          <label>Title</label>
          <div>{list.title}</div>
        </div>
        <div className='content-title'>
          <label>Message</label>
          <div>
            <ViewDesc htmlFile={list.message} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Card
