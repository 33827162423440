import { serviceHandler } from '../../utils/serviceHandler'
import apiEndPoints from '../../utils/apiUrlConstants'

const { User } = apiEndPoints

export const getFAQ = (page = 1, value = '', sort = 'desc') => {
  return serviceHandler({
    url: `faq`,
    methodType: 'get',

    params: {
      page: page,
      limit: 100
      // searchKey:"first_name",
      // searchValue:value,
      // sortKey:"blocked_on",
      // sortOrder:sort?sort:"desc"
    }
  })
}

export const getResourcesAll = (page = 1, value = '', sort = 'desc') => {
  return serviceHandler({
    url: `resources`,
    methodType: 'get',

    params: {
      page: page,
      limit: 100
      // searchKey:"first_name",
      // searchValue:value,
      // sortKey:"repotred_on",
      // sortOrder:sort?sort:"desc"
    }
  })
}

export const createFaq = (payload) => {
  return serviceHandler({
    url: `faq`,
    methodType: 'post',
    payload
  })
}

export const createResource = (payload) => {
  return serviceHandler({
    url: `resources`,
    methodType: 'post',
    payload
  })
}
export const deleteFaq = (payload) => {
  return serviceHandler({
    url: `faq/${payload.id}`,
    methodType: 'delete',
    payload
  })
}
export const deleteResource = (payload) => {
  return serviceHandler({
    url: `resources/${payload.id}`,
    methodType: 'delete',
    payload
  })
}
export const updateFaq = (payload) => {
  return serviceHandler({
    url: `faq/${payload.id}`,
    methodType: 'patch',
    payload
  })
}

export const updateResource = (payload) => {
  return serviceHandler({
    url: `resources/${payload.id}`,
    methodType: 'patch',
    payload
  })
}
export const updateOrdinalFaq = (payload) => {
  return serviceHandler({
    url: `faq`,
    methodType: 'put',
    payload
  })
}

export const updateOrdinalResource = (payload) => {
  return serviceHandler({
    url: `resources`,
    methodType: 'put',
    payload
  })
}
