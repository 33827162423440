/**
 * A mirror copy of this this is located in the same respective path in the Admin package.
 */
/**
 * @type {string} The development s3 bucket name
 */
const DEV_BUCKET_NAME = '/dateability-dev-media'
/**
 * @type {string} The production S3 bucket name
 */
const PROD_BUCKET_NAME = '/media.dateabilityapp.com'

/**
 * @typedef {object|undefined} urlProps
 * @property {string|number|undefined} height
 * @property {string|number|undefined} width
 */

/**
 *
 * @param source {string} a uri or url to a image
 * @param props {urlProps}
 * @returns {string|string|*}
 */
const getUrlForImage = (source, props = undefined) => {
  if (!source || source.length <= 0) {
    return source
  }

  if (source.startsWith('http')) {
    source = new URL(source).pathname
  }

  if (source.startsWith(DEV_BUCKET_NAME) || source.startsWith(PROD_BUCKET_NAME)) {
    const pieces = source.split('/')
    pieces.shift() // "" for the leading slash
    pieces.shift() // pops the bucket name out
    source = '/' + pieces.join('/')
  }

  if (props) {
    let query = []
    if (props.width) {
      query.push(`width=${props.width}`)
    }
    if (props.height) {
      query.push(`height=${props.height}`)
    }
    source = source + '?' + query.join('&')
  }

  return `${process.env.REACT_APP_IMAGE_RESIZER_DOMAIN || ''}${source}`
}

module.exports = { getUrlForImage }
