import React, { useEffect, useState } from 'react'
import { Button, Layout } from 'antd'
import PageHeader from '../../components/spinner/pageHeader/PageHeader'
import './Index.scss'
import UserKpi from './children/userKpi'
import ActionKpi from './children/actionKpi'
import DeetsTable from './children/deetsTable'
import GenderTable from './children/genderTable'
import AgeTable from './children/ageTable'
import IntrestTable from './children/intrestTable'
import LineCharts from './children/lineCharts'
import {
  getUserList,
  getGrowth,
  getActivity,
  getDisability,
  getGender,
  getInterest,
  getExportCSV,
  getAgeRange
} from './services'
import Spinner from '../../components/spinner/Spinner'
import { DownloadCsv } from './children/downloadCsv'

const Dashboard = () => {
  const [loading, setLoading] = useState(true)
  const [userList, setUserList] = useState({})
  const [actionList, setActionList] = useState({})
  const [genderList, setGenderList] = useState([])
  const [interestList, setInterestList] = useState([])
  const [deetsList, setDeetsList] = useState([])
  const [growthData, setGrowthData] = useState([])
  const [userPeriod, setUserPeriod] = useState('MONTHLY')
  const [actionPeriod, setActionPeriod] = useState('MONTHLY')
  const [growthAvg, setGrowthAvg] = useState(0)
  const [ageList, setAgeList] = useState([])

  useEffect(() => {
    getUserList({ period: userPeriod }).then((res) => {
      if (res.data) {
        setUserList(res.data.data)
      }
    })
    getGrowth({ period: userPeriod }).then((res) => {
      if (res.data) {
        setGrowthData(res.data.data.result)
      }
      setLoading(false)
      setGrowthAvg(0)
    })
  }, [userPeriod])
  useEffect(() => {
    getActivity({ period: actionPeriod }).then((res) => {
      if (res.data) {
        setActionList(res.data.data)
      }
    })
    getGender({ period: actionPeriod }).then((res) => {
      if (res.data) {
        setGenderList(res.data.data.result)
      }
    })
    getDisability({ period: actionPeriod }).then((res) => {
      if (res.data) {
        setDeetsList(res.data.data.result)
      }
    })
    getInterest({ period: actionPeriod }).then((res) => {
      if (res.data) {
        setInterestList(res.data.data.result)
      }
    })
    getAgeRange({ period: actionPeriod }).then((res) => {
      if (res.data) {
        setAgeList(res.data.data.result)
      }
    })
  }, [actionPeriod])
  const onDownload = () => {
    getExportCSV().then((res) => {
      DownloadCsv(res?.data?.data?.result)
    })
  }
  return (
    <Layout className='dashboard-home'>
      <PageHeader heading='Dashboard' />
      <div className='wrapper page_scroll'>
        <div className='main_head'>
          <h2>Dashboard</h2>
          <Button
            type='primary'
            className='secondary-btn'
            onClick={onDownload}
          >
            Export
          </Button>
        </div>
        <p className='desc'>View details relevant to users and export it into CSV.</p>
        <div className='border-line' />
        <UserKpi
          userList={userList}
          setUserPeriod={setUserPeriod}
          userPeriod={userPeriod}
        />
        <LineCharts
          growthData={growthData}
          userPeriod={userPeriod}
          setGrowthAvg={setGrowthAvg}
          growthAvg={growthAvg}
        />
        <div className='border-line' />
        <ActionKpi
          actionList={actionList}
          setActionPeriod={setActionPeriod}
          actionPeriod={actionPeriod}
        />
        <div className='border-line' />
        <DeetsTable deetsList={deetsList} />
        <div className='border-line' />
        <GenderTable genderList={genderList} />
        <div className='border-line' />
        <AgeTable ageList={ageList} />
        <div className='border-line' />
        <IntrestTable interestList={interestList} />
        <div className='border-line' />
      </div>
      {loading && <Spinner />}
    </Layout>
  )
}
export default Dashboard
