import React, { useState, useEffect } from 'react'
import { Layout, Modal, Avatar, Image, Table, Row, Col, message, Dropdown, Menu } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import './ProfileVerification.scss'
import PageHeader from '../../components/spinner/pageHeader/PageHeader'
import ChatIcon from '../../assets/chat.svg'
import TickIcon from '../../assets/tick.svg'
import CloseIcon from '../../assets/cross.svg'
import SortIcon from '../../assets/sort.svg'
import FilterIcon from '../../assets/filter.svg'
import { getProfiles } from './servicesProfile'
import Spinner from '../../components/spinner/Spinner'
import moment from 'moment'
import { userApprove, userReject } from '../UserManagement/services'
import ProfileDetails from './ProfileDetails'

import { getUrlForImage } from '../../utils/imageHelper'

const { confirm } = Modal
const ProfileVerification = function () {
  const [profileList, setProfileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPage, setTotalPage] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)
  const [isDetail, setIsDetail] = useState(false)
  const [detailData, setDetailData] = useState()

  useEffect(() => {
    getUsers(1)
  }, [])

  const onSearch = (value) => {
    getUsers(1, value)
  }

  const getUsers = async (page = 1, search = '', sort) => {
    setLoading(true)
    const Data = await getProfiles(page, search, sort)
    setLoading(false)
    setProfileList(Data ? Data.data.data.photos : [])
    setTotalPage(Data ? Data.data.data.total : 1)
  }

  const menu = (
    <Menu
      onClick={(e) => (e.key === '0' ? getUsers(1, '', 'desc') : getUsers(1, '', 'asc'))}
      items={[
        {
          label: 'Descending',
          key: '0'
        },
        {
          label: 'Ascending',
          key: '1'
        }
      ]}
    ></Menu>
  )

  const handleDelete = (record, event) => {
    event.stopPropagation()
    confirm({
      title: 'Are you sure you want to reject this profile?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action will delete their user account',

      onOk() {
        rejectProfile(record.user_id)
      },

      onCancel() {
        console.log('Cancel')
      }
    })
  }

  const handleAccept = (record, event) => {
    event.stopPropagation()
    confirm({
      title: 'Are you sure you want to approve their profile?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action will approve their profile and add the yellow check mark.',

      onOk() {
        approveProfile(record.user_id)
      },

      onCancel() {
        console.log('Cancel')
      }
    })
  }

  const approveProfile = async (id) => {
    setLoading(true)

    const Data = await userApprove(id)
    setLoading(false)
    message.success('Approved Profile')
    await getUsers(1)
  }
  const rejectProfile = async (id) => {
    setLoading(true)

    const Data = await userReject(id)

    setLoading(false)
    message.success('Rejected Profile')
    await getUsers(1)
  }
  const eventTableHeader = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: 325,
      render: (e, record) => (
        <Row className='image-name'>
          <Col flex='0 1 50px'>
            <Avatar
              size={60}
              src={
                record?.verification_photos &&
                record?.verification_photos.length > 0 &&
                getUrlForImage(record?.verification_photos[0]?.photo_url, { width: 60 })
              }
            />
          </Col>
          <Col
            flex='1 1 200px'
            className='name-col'
          >
            <div className='table-name-head'>Name</div>

            <div className='user-name-col'>{record.first_name + ' ' + record.last_name}</div>
          </Col>
        </Row>
      )
    },
    {
      title: '',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      render: (e, record) => (
        <div>
          <div className='table-name-head'>Date submitted</div>

          <div className='user-name-col'>{moment(record.created_on).format('DD MMM, YYYY')}</div>
        </div>
      )
    },

    {
      title: '',
      dataIndex: 'hasLegacyCapability',
      key: 'hasLegacyCapability',
      render: (e, record) => (
        <div className='profile-images'>
          {record?.verification_photos.slice(0, 3).map((item) => (
            <Image
              key={item.id}
              width={100}
              src={getUrlForImage(item.photo_url, { width: 100 })}
            />
          ))}
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'hasLegacyCapability',
      key: 'hasLegacyCapability',
      width: 200,
      render: (e, record) => (
        <div>
          <img
            src={ChatIcon}
            className='action-icon'
          />
          <img
            src={TickIcon}
            className='action-icon'
            onClick={(event) => handleAccept(record, event)}
          />
          <img
            src={CloseIcon}
            className='action-icon'
            onClick={(event) => handleDelete(record, event)}
          />
        </div>
      )
    }
  ]

  return (
    <Layout className='dashboard-layout-home'>
      <PageHeader
        heading='Profile Verification'
        isSearch={isDetail ? false : true}
        onSearch={onSearch}
      />
      {!isDetail ? (
        <div className='profile-body'>
          <div className='profile-head'>Profile Verification Management</div>
          <div className='profile-subhead'>Approve or Reject Profiles based on their Image Submissions.</div>
          <div className='table-section'>
            <div className='table-header'>
              <div className='table-header-name'>Profile Verification</div>
              <div className='sort-filter'>
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  placement='bottomCenter'
                >
                  <div>
                    <img
                      src={SortIcon}
                      className='action-icon'
                    />{' '}
                    Sort
                  </div>
                </Dropdown>
                {/* <div><img src={FilterIcon} className="action-icon"/> Filter</div> */}
              </div>
            </div>
            <Table
              dataSource={profileList}
              columns={eventTableHeader}
              className={'event-table'}
              showHeader={false}
              // scroll={{ x: 1000, y: "calc(100% - 60px)" }}
              rowKey={(record) => record.id}
              pagination={{
                onChange: (page) => {
                  setDefaultPage(page)
                  getUsers(page)
                },
                current: defaultPage,
                pageSize: 10,
                total: totalPage,
                hideOnSinglePage: true,
                showSizeChanger: false
              }}
              rowClassName={(record, index) => 'disable-row'}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    event.stopPropagation()
                    setDetailData(record)
                    setIsDetail(true)
                  } // click row
                }
              }}
            />
          </div>
        </div>
      ) : (
        <ProfileDetails
          detailData={detailData}
          goBack={setIsDetail}
        />
      )}

      {loading && <Spinner />}
    </Layout>
  )
}
export default ProfileVerification
