import { DownOutlined, MenuOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Collapse } from 'antd'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
// import { ItemTypes } from './ItemTypes.js'
const style = {
  // border: '1px dashed gray',
  // padding: '0.5rem 1rem',
  // marginBottom: '.5rem',
  // backgroundColor: 'white',
  // cursor: 'move',
}
export const Card = ({
  id,
  text,
  index,
  moveCard,
  item,
  selectedType,
  editContent,
  deleteContent,
  onPanelClick,
  selectedPanel
}) => {
  const ref = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={handlerId}
    >
      <div className='report_heads'>
        <div className='heading'>
          <MenuOutlined style={{ marginRight: '15px', cursor: 'pointer' }} />
          <div>{selectedType == 'FAQ' ? item.question : item.title}</div>
        </div>
        <div
          onClick={() => {
            onPanelClick(item)
          }}
          className='expand_icon'
        >
          {selectedPanel === item.id ? (
            <DownOutlined style={{ fontSize: 12 }} />
          ) : (
            <RightOutlined style={{ fontSize: 12 }} />
          )}
        </div>
      </div>
      {selectedPanel === item.id && (
        <div className='act_details'>
          <div className='report_list'>
            <div
              className='report_content'
              dangerouslySetInnerHTML={{ __html: selectedType == 'FAQ' ? item.answer : item.description }}
            ></div>
          </div>
          <div className='content_action'>
            <Button
              type='primary'
              className='edit_btn'
              ghost
              style={{ marginLeft: '15px', borderRadius: 8 }}
              onClick={() => {
                editContent(item)
              }}
            >
              Edit
            </Button>
            <Button
              type='text'
              danger
              style={{ marginLeft: '10px' }}
              onClick={() => {
                deleteContent(item)
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
