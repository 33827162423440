import React, { useState, useEffect } from 'react'
import { Layout, Menu, Avatar, Image, Table, Row, Col, message, Modal, Dropdown } from 'antd'
import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import './ContactManagement.scss'
import PageHeader from '../../components/spinner/pageHeader/PageHeader'
import ChatIcon from '../../assets/chat.svg'
import moment from 'moment'
import CloseIcon from '../../assets/delete.svg'
import SortIcon from '../../assets/sort.svg'
import FilterIcon from '../../assets/filter.svg'
import Spinner from '../../components/spinner/Spinner'
import { getContacts } from './contactServices'
import { userDeActivate } from '../UserManagement/services'

import { getUrlForImage } from '../../utils/imageHelper'

const { confirm } = Modal
const ContactManagement = function () {
  const [profileList, setProfileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPage, setTotalPage] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)
  const [searchKey, setSearchKey] = useState('')

  useEffect(() => {
    getContactsList(1)
  }, [])
  const onSearch = (value) => {
    setSearchKey(value)
    getContactsList(1, value)
  }
  const getContactsList = async (page = 1, search = '', sort = 'desc') => {
    setLoading(true)
    const Data = await getContacts(page, search, sort)
    setLoading(false)
    setProfileList(Data ? Data.data.data.contactUs : [])
    setTotalPage(Data ? Data.data.data.total : 1)
  }

  const handleDelete = (record, event) => {
    event.stopPropagation()
    confirm({
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleOutlined />,
      content: ' This action cannot be undone.',

      onOk() {
        deactivateAccount(record.user_id)
      },

      onCancel() {
        console.log('Cancel')
      }
    })
  }

  const deactivateAccount = async (id) => {
    setLoading(true)
    const Data = await userDeActivate(id)
    setLoading(false)
    message.success(Data?.data.msg)
  }

  const eventTableHeader = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      className: 'name-td',
      width: 285,
      render: (e, record) => (
        <Row className='image-name'>
          <Col flex='0 1 50px'>
            <Avatar
              size={70}
              src={
                record?.user?.UserPhotos &&
                record?.user?.UserPhotos.length > 0 &&
                getUrlForImage(record?.user?.UserPhotos[0].photo_url, { width: 70 })
              }
            />
          </Col>
          <Col
            flex='1 1 200px'
            className='name-col'
          >
            <div className='table-name-head'>Name</div>

            <div className='user-name-col'>{record.user?.first_name + ' ' + record.user?.last_name}</div>
          </Col>
        </Row>
      )
    },
    {
      title: '',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      className: 'date-td',
      render: (e, record) => (
        <div>
          <div className='table-name-head'>Contacted on date</div>

          <div className='user-name-col'>{moment(record.user?.last_login_time).format('DD MMM, YYYY')}</div>
        </div>
      )
    },

    {
      title: '',
      dataIndex: 'hasLegacyCapability',
      key: 'hasLegacyCapability',
      className: 'action-td',
      width: 200,
      render: (e, record) => (
        <div>
          <img
            src={ChatIcon}
            className='action-icon'
          />

          <img
            src={CloseIcon}
            className='action-icon'
            onClick={(event) => handleDelete(record, event)}
          />
        </div>
      )
    }
    // {
    //   title: "",
    //   dataIndex: "startDate",
    //   key: "startDate",
    //   className:"note-td",
    //   render: (e, record) => (
    //     <div>
    //       <div className="table-name-head">
    //         Note
    //         </div>

    //           <div
    //             className="user-name-col"
    //           >
    //             {record.message}
    //           </div>
    //     </div>
    //   )
    // },
  ]

  const menu = (
    <Menu
      onClick={(e) => (e.key === '0' ? getContactsList(1, searchKey, 'desc') : getContactsList(1, searchKey, 'asc'))}
      items={[
        {
          label: 'Descending',
          key: '0'
        },
        {
          label: 'Ascending',
          key: '1'
        }
      ]}
    ></Menu>
  )

  return (
    <Layout className='dashboard-layout-home'>
      <PageHeader
        heading='Contact us Requests'
        isSearch
        onSearch={onSearch}
      />
      <div className='profile-body'>
        <div className='profile-head'>Contact us Requests</div>
        <div className='profile-subhead'>See the list of users who have contacted.</div>
        <div className='table-section'>
          <div className='table-header'>
            <div className='table-header-name'>Users</div>
            <div className='sort-filter'>
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement='bottomCenter'
              >
                <div>
                  <img
                    src={SortIcon}
                    className='action-icon'
                  />{' '}
                  Sort
                </div>
              </Dropdown>
              {/* <div><img src={FilterIcon} className="action-icon"/> Filter</div> */}
            </div>
          </div>
          <Table
            dataSource={profileList}
            columns={eventTableHeader}
            className={'event-table'}
            showHeader={false}
            // scroll={{ x: 1000, y: "calc(100% - 60px)" }}
            rowKey={(record) => record.user_id}
            pagination={{
              onChange: (page) => {
                setDefaultPage(page)
                getContactsList(page, searchKey)
              },
              current: defaultPage,
              pageSize: 20,
              total: totalPage,
              hideOnSinglePage: true,
              showSizeChanger: false
            }}
            key={(record) => record.user_id}
            rowClassName={(record, index) => 'next-row'}
            expandRowByClick={true}
            expandIcon={false}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => {
            //       event.stopPropagation();
            //     }, // click row
            //   };
            // }}
            expandable={{
              expandedRowRender: (record) => (
                <div>
                  <div className='table-name-head'>Note</div>

                  <div className='user-name-col'>{record.message}</div>
                </div>
              ),
              rowExpandable: (record) => record.name !== 'Not Expandable'
            }}
          />
        </div>
      </div>
      {loading && <Spinner />}
    </Layout>
  )
}
export default ContactManagement
