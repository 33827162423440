export const DownloadCsv = (items) => {
  let csv = ''
  for (let row = 0; row < items.length; row++) {
    const keysAmount = Object.keys(items[row]).length
    let keysCounter = 0

    if (row === 0) {
      for (const key in items[row]) {
        csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
        keysCounter++
      }
    } else {
      for (const key in items[row]) {
        csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
        keysCounter++
      }
    }
    keysCounter = 0
  }
  // console.log(csv);
  const hiddenElement = document.createElement('a')
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`
  hiddenElement.target = '_blank'
  hiddenElement.download = 'dashboard.csv'
  hiddenElement.click()
}
