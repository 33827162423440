import { Button } from 'antd'
import React from 'react'
import KpiCards from './kpiCards'
import { action } from '../children/constants'

const ActionKpi = (props) => {
  const { actionList, setActionPeriod, actionPeriod } = props
  return (
    <>
      <div className='sub_head'>
        <h2>Action KPI</h2>
        <div>
          <Button
            type='primary1'
            className={actionPeriod === 'WEEKLY' ? 'active-btn' : 'report-btn'}
            onClick={() => {
              setActionPeriod('WEEKLY')
            }}
          >
            Weekly
          </Button>
          <Button
            type='primary1'
            className={actionPeriod === 'MONTHLY' ? 'active-btn' : 'report-btn'}
            onClick={() => {
              setActionPeriod('MONTHLY')
            }}
          >
            Monthly
          </Button>
          <Button
            type='primary1'
            className={actionPeriod === 'YEARLY' ? 'active-btn' : 'report-btn'}
            onClick={() => {
              setActionPeriod('YEARLY')
            }}
          >
            Yearly
          </Button>
          <Button
            type='primary1'
            className={actionPeriod === 'ALL' ? 'active-btn' : 'report-btn'}
            onClick={() => {
              setActionPeriod('ALL')
            }}
          >
            All
          </Button>
        </div>
      </div>
      <div className='kpiwrap'>
        {action.map((item) => {
          return (
            <KpiCards
              count={actionList[item.key]}
              label={item.label}
            />
          )
        })}
      </div>
    </>
  )
}
export default ActionKpi
