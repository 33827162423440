import { Layout } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import React, { useEffect, useCallback, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import PageHeader from '../../components/spinner/pageHeader/PageHeader'
import { userDataDetails } from '../UserManagement/services'
import AccountCard from './children/AccountCard'
import ActivityCard from './children/ActivityCard'
import BasicCard from './children/basicCard'
import StatusCard from './children/StatusCard'
import Spinner from '../../components/spinner/Spinner'
import ProfilePhotos from './children/ProfilePhotos'

import '../UserManagement//userMngmt.scss'

const UserDetails = (props) => {
  const { userInfoId, setUserInfos } = props
  const [userDetails, setUserDetails] = useState()
  const [loading, setLoading] = useState(false)

  const params = useParams()
  const navigate = useNavigate()

  const getEventDetails = useCallback(
    async (id) => {
      setLoading(true)
      const Data = await userDataDetails(id || userInfoId)
      setUserDetails(Data.data.data)
      setLoading(false)
    },
    [userInfoId]
  )

  useEffect(() => {
    getEventDetails(params.id)
  }, [params.id, getEventDetails])

  return (
    <Layout className='dashboard-layout-home'>
      {!userInfoId && <PageHeader heading='User Details' />}
      {userDetails && (
        <div className='wrapper page_scroll'>
          <div className='main_head'>
            <div className='details-head'>
              <ArrowLeftOutlined
                className='back-arrow'
                onClick={() => {
                  userInfoId ? setUserInfos(false) : navigate(`/admin/user-management`)
                }}
              />
              <h2>{userDetails.first_name + ' ' + userDetails.last_name}</h2>
            </div>
            {/* <Button type="primary" className="secondary-btn">Export Details</Button> */}
          </div>
          <AccountCard userDetails={userDetails} />
          <ActivityCard userDetails={userDetails} />
          <BasicCard userDetails={userDetails} />
          <ProfilePhotos userDetails={userDetails} />
          {/* <ReportCard userDetails={userDetails}/>
           <BlockCard userDetails={userDetails}/> */}
          <StatusCard
            userDetails={userDetails}
            refreshUser={getEventDetails}
          />
        </div>
      )}
      {loading && <Spinner />}
    </Layout>
  )
}
export default UserDetails
