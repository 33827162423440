import { Tag } from 'antd'

const color = {
  DELETED: 'black',
  TERMINATED: 'grey',
  ACTIVE: 'green',
  SUSPENDED: 'red',
  PENDING: 'yellow',
  REJECTED: 'red'
}

export const UserStatusTag = ({ status, ...props }) => (
  <Tag
    color={color[status]}
    {...props}
  >
    {status}
  </Tag>
)
