import React from 'react'
import { Collapse, Space, Image, Row, Col } from 'antd'
import { activityFields } from './constants'

import { getUrlForImage } from '../../../utils/imageHelper'

const { Panel } = Collapse

const ProfilePhotos = ({ userDetails }) => {
  return (
    <div className='user_accord'>
      <Space direction='vertical'>
        <Collapse
          collapsible='header'
          defaultActiveKey={['1']}
          expandIconPosition='end'
          size='large'
        >
          <Panel
            header={
              <div className='header main_head'>
                <div>Profile Photos</div>
              </div>
            }
            key='1'
          >
            <div className='act_details'>
              <Row>
                {userDetails &&
                  userDetails.UserPhotos.map((item) => (
                    <Col
                      key={item.id}
                      style={{ margin: '10px' }}
                    >
                      {item && item.photo_url ? (
                        <Image
                          style={{ objectFit: 'cover' }}
                          width={100}
                          height={100}
                          preview={getUrlForImage(item.photo_url, { width: 100, height: 100 })}
                          src={getUrlForImage(item.photo_url, { width: 600, height: 600 })}
                        />
                      ) : null}
                    </Col>
                  ))}
              </Row>
            </div>
          </Panel>
        </Collapse>
      </Space>
    </div>
  )
}
export default ProfilePhotos
