import { serviceHandler } from '../../utils/serviceHandler'

export const getAnnouncement = (payload) => {
  return serviceHandler({
    url: `announcements?limit=${10}&page=${payload.page}&search=${''}`,
    methodType: 'get'
  })
}
export const addAnnouncement = (payload) => {
  return serviceHandler({
    url: `announcements`,
    methodType: 'post',
    payload
  })
}
export const deleteAnnouncement = (payload) => {
  return serviceHandler({
    url: `announcements/${payload.id}`,
    methodType: 'delete'
  })
}
