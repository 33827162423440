import React, { useState } from 'react'
import { Button, message, Tag, Popconfirm } from 'antd'

import { userActivate, userDeActivate, userMarkPending, userReject, userApprove } from '../../UserManagement/services'
import { UserStatusTag } from '../../../components/UserStatusTag'
import Spinner from '../../../components/spinner/Spinner'
import AccountReviewButtons from '../../PendingAccounts/AccountReviewButtons'

const StatusCard = ({ userDetails, refreshUser }) => {
  const [loading, setLoading] = useState(false)

  const deleteAccount = async (id) => {
    setLoading(true)
    const Data = await userDeActivate(id)
    setLoading(false)
    message.success(Data?.data.msg)
    refreshUser(userDetails.id)
  }

  const activateAccount = async (id) => {
    setLoading(true)
    const Data = await userActivate(id)
    setLoading(false)
    message.success(Data?.data.msg)
    refreshUser(userDetails.id)
  }

  const markPending = async (id) => {
    setLoading(true)
    const Data = await userMarkPending(id)
    setLoading(false)
    message.success(Data?.data.msg)
    refreshUser(userDetails.id)
  }

  const approveProfile = async (id) => {
    setLoading(true)
    const Data = await userApprove(id)
    setLoading(false)
    message.success('Approved Profile')
    refreshUser(userDetails.id)
  }
  const rejectProfile = async (id) => {
    setLoading(true)
    const Data = await userReject(id)
    setLoading(false)
    message.success('Rejected Profile')
    refreshUser(userDetails.id)
  }

  return (
    <div className='card'>
      <div className='header main_head'>
        <h3>Account Status</h3>
      </div>
      <div
        className='content'
        style={{ flexDirection: 'column' }}
      >
        <div style={{ marginBottom: 10 }}>
          <div style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10 }}>Profile Verification Status</div>
          <div style={{ marginBottom: 10 }}>
            {userDetails.is_verified && <Tag color='green'>VERIFIED PROFILE</Tag>}
            {userDetails.is_verified === false && userDetails.account_status !== 'DELETED' && (
              <Tag color='grey'>UNVERIFIED PROFILE</Tag>
            )}
            {userDetails.is_verified === false && userDetails.account_status === 'DELETED' && (
              <Tag color='black'>REJECTED PROFILE</Tag>
            )}
          </div>
          <p>
            All profiles begin as unverified. Once approved, the profile is marked as verified as a sign of trust on the
            consumer site.
            <br />
            A rejected profile will be marked as unverified (the default) and also have their account status set as
            DELETED.
            <br />A deleted profile that was never verified will appear as "Rejected Profile".
          </p>

          <div style={{ display: 'flex', gap: 15 }}>
            <Button
              type='primary'
              disabled={userDetails.is_verified || userDetails.account_status === 'DELETED'}
              onClick={() => approveProfile(userDetails.id)}
            >
              Approve Profile
            </Button>
            <Button
              type='primary'
              disabled={!userDetails.is_verified && userDetails.account_status === 'DELETED'}
              danger
              onClick={() => rejectProfile(userDetails.id)}
            >
              Reject Profile
            </Button>
          </div>
        </div>
      </div>
      <div
        className='content'
        style={{ marginTop: 10, flexDirection: 'column' }}
      >
        <div>
          <div style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10 }}>Account Status</div>
          <div style={{ marginBottom: 10 }}>
            <UserStatusTag status={userDetails.account_status} />
          </div>
          {userDetails.account_status === 'REJECTED' ? (
            <div style={{ marginBottom: 10 }}>
              <div>
                <strong>Status Reason:</strong> {userDetails.account_status_reason}
              </div>
              <div>
                <strong>Additional Info:</strong> {userDetails.account_status_reason_message}
              </div>
            </div>
          ) : null}
          <p>
            All new accounts begin as PENDING. Pending users can explore the site but not be seen by others. <br />
            Approving an account will mark it as ACTIVE and the user will be visible to others. <br />
            Rejecting an account will mark it as REJECTED.
          </p>

          <div style={{ display: 'flex', gap: 15 }}>
            <AccountReviewButtons
              userDetail={userDetails}
              onComplete={() => refreshUser(userDetails.id)}
            />
            <Popconfirm
              title='Are you sure to mark this user as pending review?'
              onConfirm={() => markPending(userDetails.id)}
              disabled={userDetails.account_status === 'PENDING'}
              okText='Yes, Update User'
              cancelText='Cancel'
            >
              <Button
                type='default'
                disabled={userDetails.account_status === 'PENDING'}
              >
                Mark as Pending Review
              </Button>
            </Popconfirm>
            {userDetails.account_status === 'DELETED' ? (
              <Popconfirm
                title='Are you sure to activate this deleted user?'
                onConfirm={() => activateAccount(userDetails.id)}
                okText='Yes, Activate User'
                cancelText='Cancel'
              >
                <Button
                  type='default'
                  danger={true}
                >
                  Activate Deleted Account
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                disabled={userDetails.account_status === 'DELETED'}
                title='Are you sure to mark this user as deleted?'
                onConfirm={() => deleteAccount(userDetails.id)}
                okText='Yes, Delete User'
                cancelText='Cancel'
              >
                <Button
                  type='default'
                  danger={true}
                  disabled={userDetails.account_status === 'DELETED'}
                >
                  Delete Account
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  )
}
export default StatusCard
