/* eslint-disable import/no-anonymous-default-export */
import { message } from 'antd'
import axios from 'axios'
import { getCookies, removeCookies } from './Utility'

const client = axios.create({
  baseURL: process.env.REACT_APP_HOST
})

client.interceptors.request.use((config) => {
  const token = getCookies('user_token')

  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }

  return config
})

client.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error.response)

    if (error.response.status === 401) {
      removeCookies('user_token')
      window.location.href = '/'
      return
    }

    message.error(error.response.data.message)

    return Promise.reject(error)
  }
)

export const serviceHandler = ({ url, payload, methodType = 'get', params }) =>
  client({
    url,
    method: methodType,
    params: params,
    data: payload
  })
    .then(({ data }) => ({
      success: true,
      data
    }))
    .catch((error) => {
      console.log(`Error occurred while calling ${url} : ${error}`)

      return {
        success: false,
        data: error.response
      }
    })
