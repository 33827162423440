import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Layout, message, Modal, Pagination } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import PageHeader from '../../components/spinner/pageHeader/PageHeader'
import './Index.scss'
import SortIcon from '../../assets/sort.svg'
import Spinner from '../../components/spinner/Spinner'
import Card from './childrens/card'
import AddAnnounce from './childrens/AddAnnounce'
import { getAnnouncement, addAnnouncement, deleteAnnouncement } from './services'

const { confirm } = Modal
const Announcement = () => {
  const [loading, setLoading] = useState(true)
  const [showAdd, setShowAdd] = useState(false)
  const [dataList, setDataList] = useState({})
  const [htmlFile, setHtmlFile] = useState('')
  const [title, setTitle] = useState('')
  const [page, setPage] = useState(1)

  const getAllData = (pageNo) => {
    getAnnouncement({ page: pageNo }).then((res) => {
      if (res.success) {
        setDataList(res.data.data)
        setLoading(false)
      }
    })
  }
  useEffect(() => {
    getAllData(1)
  }, [])
  const onAddAnnouncement = () => {
    addAnnouncement({ title: title, message: htmlFile }).then((res) => {
      if (res.success) {
        message.success('Announcement added successfully')
        getAllData()
        setHtmlFile('')
        setTitle('')
        setShowAdd(false)
      }
    })
  }
  const onDelete = (data) => {
    confirm({
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleOutlined />,
      content: ' This action cannot be undone.',

      onOk() {
        deleteAnnouncement({ id: data.id }).then(() => {
          getAllData()
          message.success('Announcement deleted successfully')
        })
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }
  const onChangePage = (e) => {
    setPage(e)
    getAllData(e)
    setLoading(true)
  }
  return (
    <Layout className='announce-home'>
      <PageHeader heading='Announcements' />
      <div className='wrapper page_scroll'>
        <div className='main_head'>
          <h2>Announcements</h2>
          <Button
            type='primary'
            className='send-btn'
            onClick={() => {
              setShowAdd(true)
            }}
          >
            Send Announcement
          </Button>
        </div>
        <p className='desc'>See the announcements created across the platform.</p>
        <div className='pagination'>
          <Pagination
            total={dataList.count}
            current={page}
            pageSize={10}
            showSizeChanger={false}
            onChange={(e) => {
              onChangePage(e)
            }}
          />
        </div>
        <div className='table-section'>
          <div className='table-header'>
            <div className='header-name'>Announcements</div>
            <div className='sort-filter'>
              <div>
                <img
                  src={SortIcon}
                  alt=''
                  className='action-icon'
                />{' '}
                Sort
              </div>
            </div>
          </div>
          {_.map(dataList.announcements, (list) => {
            return (
              <Card
                list={list}
                onDelete={onDelete}
              />
            )
          })}
        </div>
      </div>
      {showAdd && (
        <AddAnnounce
          isModalVisible={showAdd}
          setIsModalVisible={setShowAdd}
          setHtmlFile={setHtmlFile}
          htmlFile={htmlFile}
          title={title}
          setTitle={setTitle}
          onAddAnnouncement={onAddAnnouncement}
        />
      )}
      {loading && <Spinner />}
    </Layout>
  )
}
export default Announcement
