import React, { useState, useEffect } from 'react'
import { Layout, Menu, Avatar, Dropdown, Space } from 'antd'

import './AdminDashboard.scss'
import { RightOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import UserManagement from '../UserManagement/UserManagement'
import UserDetails from '../UserDetails'
import { adminSideMenuLinks } from '../../utils/Utility'
import SideLogo from '../../assets/logo-side-menu.svg'
import ProfileVerification from '../ProfileVerification/ProfileVerification'
import AbuseManagement from '../AbuseManagement/AbuseManagement'
import Dashboard from '../Dashboard'
import DynamicContent from '../DynamicContent/DynamicContent'
import ContactManagement from '../ContactManagement/ContactManagement'
import Announcement from '../Announcement'
import Messaging from '../Messaging/Messaging'
import PendingAccounts from '../PendingAccounts/PendingAccounts'

const { Content, Sider } = Layout

const AdminDashboard = function () {
  const [routePath, setRoutePath] = useState('/admin/user-management')
  const location = useLocation()

  useEffect(() => {
    setRoutePath(location.pathname)
  }, [location])
  return (
    <Layout className='dashboard-layout-home'>
      <Sider
        width={250}
        className='dashboard-sider'
      >
        <div className='app-logo'>
          <img
            alt='Dateability'
            src={SideLogo}
            className='app-logo-img'
          />
        </div>

        <Menu
          mode='inline'
          selectedKeys={[routePath]}
          style={{ height: '100%', borderRight: 0 }}
        >
          {adminSideMenuLinks.map((item) => (
            <Menu.Item
              key={item.link}
              className='side-menu-item'
            >
              <div className='side-menu-div'>
                <Link to={item.link}>
                  <img
                    src={item.icon}
                    alt={item.name}
                    className='menu-logo'
                  />
                  {item.name}
                </Link>
              </div>
            </Menu.Item>
          ))}
        </Menu>
        <div className='user-logout'></div>
      </Sider>
      <Layout style={{ padding: '0 0px 0px', height: '100vh', overflow: 'hidden' }}>
        <Content className='admin-content'>
          <Routes>
            <Route
              path='dashboard'
              element={<Dashboard />}
            />
            <Route
              path='user-management'
              element={<UserManagement />}
            />
            <Route
              path='user-details/:id'
              element={<UserDetails />}
            />
            <Route
              path='pending-accounts'
              element={<PendingAccounts />}
            />
            <Route
              path='profile-verification'
              element={<ProfileVerification />}
            />
            <Route
              path='abuse-management'
              element={<AbuseManagement />}
            />
            <Route
              path='dynamic-content'
              element={<DynamicContent />}
            />
            <Route
              path='contact-us-management'
              element={<ContactManagement />}
            />
            <Route
              path='announcement'
              element={<Announcement />}
            />
            <Route
              path='messaging'
              element={<Messaging />}
            />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  )
}
export default AdminDashboard
