import { Table } from 'antd'
import React from 'react'

const AgeTable = (props) => {
  const { ageList } = props

  const columns = [
    {
      title: 'Age',
      dataIndex: 'range',
      key: 'range'
    },
    {
      title: 'Number of Users',
      dataIndex: 'count',
      key: 'count'
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage'
      // render(percent){
      //   return percent + '%'
      // }
    }
  ]
  return (
    <div className='dash-table'>
      <h3>Age Breakdown</h3>
      <Table
        dataSource={ageList}
        columns={columns}
        pagination={false}
        size='small'
        bordered
      />
    </div>
  )
}
export default AgeTable
