import React from 'react'

const KpiCards = (props) => {
  const { count, label } = props
  return (
    <div className='dashbrd_card'>
      <div className='count'>{count}</div>
      <p className='label'>{label}</p>
    </div>
  )
}
export default KpiCards
