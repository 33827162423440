import { serviceHandler } from '../../utils/serviceHandler'

export const blockedsers = (page = 1, value = '', sort = 'desc') => {
  return serviceHandler({
    url: `blocked-users/admin/grouped`,
    methodType: 'get',

    params: {
      page: page,
      limit: 10,
      searchKey: 'first_name',
      searchValue: value,
      sortKey: 'last_blocked_on',
      sortOrder: sort ? sort : 'desc'
    }
  })
}

export const reportedSingleUser = (id) => {
  return serviceHandler({
    url: `reported-users/${id}`,
    methodType: 'get'
  })
}

export const deactivateUser = (id) => {
  return serviceHandler({
    url: `user/delete-account/${id}`,
    methodType: 'delete'
  })
}

export const deactivateBlockedUser = (id) => {
  return serviceHandler({
    url: `user/delete-account/${id}`,
    methodType: 'delete'
  })
}
export const reportedUsers = (page = 1, value = '', sort = 'desc') => {
  return serviceHandler({
    url: `reported-users/admin/grouped`,
    methodType: 'get',

    params: {
      page: page,
      limit: 10,
      searchKey: 'first_name',
      searchValue: value,
      sortKey: 'last_reported_on',
      sortOrder: sort ? sort : 'desc'
    }
  })
}

export const blockedUserExport = () => {
  return serviceHandler({
    url: `admin/export/blocked_users`,
    methodType: 'get'
  })
}

export const ReportedUserExport = () => {
  return serviceHandler({
    url: `admin/export/reported_users`,
    methodType: 'get'
  })
}
