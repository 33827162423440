import React, { useState, useEffect, useRef } from 'react'
import { Layout, Card, Input, Divider, Checkbox, Row, Col, Button, Menu, Dropdown, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import './Messaging.scss'
import PageHeader from '../../components/spinner/pageHeader/PageHeader'
import ChatIcon from '../../assets/chat.svg'
import TickIcon from '../../assets/tick.svg'
import CloseIcon from '../../assets/cross.svg'
import SortIcon from '../../assets/sort.svg'
import FilterIcon from '../../assets/filter.svg'
import { sendMessage } from './servicesMessage'
import Spinner from '../../components/spinner/Spinner'
import { users } from '../UserManagement/services'

const { TextArea } = Input
const CheckboxGroup = Checkbox.Group
const plainOptions = ['Push', 'Mail']
const Messaging = function () {
  const [profileList, setProfileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPage, setTotalPage] = useState(1)
  const [messageText, setMessageText] = useState('')
  const [defaultPage, setDefaultPage] = useState(1)
  const [checkedtype, setCheckedType] = useState([])
  const [checkedUserList, setCheckedUserList] = useState([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const [searchText, setSearchText] = useState(decodeURI(window.location.search?.split('=')[1] || ''))
  const listInnerRef = useRef()

  const onChange = (list) => {
    setCheckedType(list)
  }
  const onChangeUser = (list) => {
    setCheckedUserList(list)
    setIndeterminate(!!list.length && list.length < profileList.length)
    setCheckAll(list.length === profileList.length)
  }

  useEffect(() => {
    getUsers(1)
  }, [])

  const getUsers = async (page = 1, value = searchText, isNew) => {
    setLoading(true)
    const Data = await users(page, value)
    setLoading(false)
    const userslist = Data ? Data.data.data.users : []
    if (userslist) {
      const checkboxprofiles = userslist.map((item) => {
        const obj = {
          label: item.first_name + ' ' + item.last_name + ' (' + item.email + ')',
          value: item.id
        }
        return obj
      })
      if (isNew) {
        setProfileList([...checkboxprofiles])
      } else {
        setProfileList([...profileList, ...checkboxprofiles])
      }
    }
    setTotalPage(Data ? Data.data.data.total : 1)
  }

  const onSearchUser = (e) => {
    setProfileList([])
    getUsers(1, e.target.value, true)
    setSearchText(e.target.value)
  }

  const onCheckAllChange = (e) => {
    setCheckedUserList(e.target.checked ? profileList.map((item) => item.value) : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const sendMessages = async () => {
    if (checkedUserList.length && messageText.length && checkedtype.length) {
      setLoading(true)
      const payload = {
        to_user_ids: checkedUserList,
        message: messageText,
        email: checkedtype.includes('Mail') ? true : false,
        push_notification: checkedtype.includes('Push') ? true : false
      }
      const result = await sendMessage(payload)
      setLoading(false)
      message.success(result.data.msg)
      setSearchText('')
      setMessageText('')
      setCheckedType([])
      setCheckedUserList([])
      setIndeterminate(true)
      setCheckAll(false)
    } else {
      message.error('Please fill require information')
    }
  }
  const onChangeMessage = (e) => {
    setMessageText(e.target.value)
  }
  const onScroll = (e) => {
    // console.log (e.currentTarget.scrollHeight ,e.currentTarget.scrollTop )
    // if(e.currentTarget.scrollTop === 80)
    // {
    //   setDefaultPage(defaultPage+1)
    //   getUsers(defaultPage+1)
    // }
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      console.log(Math.ceil(scrollTop), Math.floor(scrollHeight), clientHeight)
      if (Math.ceil(scrollTop) + clientHeight === Math.ceil(scrollHeight)) {
        // TO SOMETHING HERE
        console.log('Reached bottom')
        setDefaultPage(defaultPage + 1)
        getUsers(defaultPage + 1)
      }
    }
  }

  const getSorted = (type) => {
    const obj = profileList.sort((a, b) => {
      const nameA = a.label.toLowerCase()
      const nameB = b.label.toLowerCase()
      if (nameA < nameB) {
        if (type == 'asc') return -1
        else return 1
      }
      if (nameA > nameB) {
        if (type == 'asc') return 1
        else return -1
      }
      return 0
    })
    const updateOnj = obj.map((item) => {
      const obj = {
        ...item,
        key: Math.random()
      }
      return obj
    })

    // setProfileList([])
    setProfileList(updateOnj)
  }
  const menu = (
    <Menu
      onClick={(e) => (e.key === '0' ? getSorted('desc') : getSorted('asc'))}
      items={[
        {
          label: 'Descending',
          key: '0'
        },
        {
          label: 'Ascending',
          key: '1'
        }
      ]}
    ></Menu>
  )

  return (
    <Layout className='dashboard-layout-home'>
      <PageHeader heading='Message' />
      <div className='profile-body'>
        <div className='header-div'>
          <div>
            <div className='profile-head'>Message</div>
            <div className='profile-subhead'>Send mesge to specific or all users</div>
          </div>
          <Button
            type='primary'
            className={'send-btn'}
            onClick={() => {
              sendMessages()
            }}
          >
            Send Message
          </Button>
        </div>

        <div className='message_type'>
          <label className='type_label'>Select Message Type -</label>
          <CheckboxGroup
            options={plainOptions}
            value={checkedtype}
            onChange={onChange}
          />
        </div>
        <Row className='message-row'>
          <Col>
            <Card
              title='Send Message to / Count: 50'
              className='user-card'
              bordered={false}
            >
              <Input
                rows={10}
                onChange={onSearchUser}
                value={searchText}
                placeholder='Search'
                prefix={<SearchOutlined />}
              />
              <div className='sort-checkbox'>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  Select all
                </Checkbox>
                <div
                  className='sort-filter'
                  style={{ width: '100px', marginTop: '20px' }}
                >
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    placement='bottomCenter'
                  >
                    <div>
                      <img
                        src={SortIcon}
                        className='action-icon'
                      />{' '}
                      Sort
                    </div>
                  </Dropdown>
                  {/* <div><img src={FilterIcon} className="action-icon"/> Filter</div> */}
                </div>
              </div>
              <Divider />
              <div style={{ height: '300px', overflow: 'hidden' }}>
                <CheckboxGroup
                  style={{ overflow: 'auto', height: '100%' }}
                  options={profileList}
                  value={checkedUserList}
                  onChange={onChangeUser}
                  onScroll={onScroll}
                  ref={listInnerRef}
                />
              </div>
            </Card>
          </Col>
          {/* <Col>
            <Card
              title="Select Type"
              className="type-card"
              bordered={false}
              style={{
                width: 300,
              }}
            >
              <CheckboxGroup options={plainOptions} value={checkedtype} onChange={onChange} />

            </Card>
          </Col> */}
          <Col>
            <Card
              title='Type your message here'
              bordered={false}
              className='message-card'
            >
              <TextArea
                rows={6}
                value={messageText}
                placeholder='Enter message here'
                onChange={onChangeMessage}
              />
            </Card>
          </Col>
        </Row>
      </div>
      {loading && <Spinner />}
    </Layout>
  )
}
export default Messaging
