import { serviceHandler } from '../../utils/serviceHandler'
import apiEndPoints from '../../utils/apiUrlConstants'

const { User } = apiEndPoints

export const sendMessage = (payload) => {
  return serviceHandler({
    url: `/message/admin`,
    methodType: 'post',

    payload
  })
}
