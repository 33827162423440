import React, { useEffect, useState } from 'react'
import './Login.scss'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Form, Input, Button, Layout, Row, Col, message, Image } from 'antd'
import Spinner from '../../components/spinner/Spinner'
import SideLogo from '../../assets/logo-side-menu.svg'
import LoginFrame from '../../assets/login-frame.svg'

import { adminLogin, verifyOtp } from './services'
import { storeCookies } from '../../utils/Utility'

const Login = () => {
  const [isOtp, setIsOtp] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const handleLoginSubmit = async (values) => {
    setLoading(true)

    if (!isOtp) {
      const payload = { email: values.email }
      const generateOtp = await adminLogin(payload)
      if (generateOtp) {
        if (generateOtp.success) setIsOtp(true)
        else message.error(generateOtp.data.data.msg)
      }
      setLoading(false)
    } else {
      const payload = { email: values.email, otp: values.otp }
      const generateOtp = await verifyOtp(payload)

      setLoading(false)
      if (generateOtp.success) {
        storeCookies('user_token', generateOtp?.data?.data?.token)
        navigate('/admin/user-management')
      } else {
        message.error(generateOtp.data.data.msg)
      }
    }
    // ServiceApi.loginAuth(values)
    // .then((response) => {
    //   if (response && response.data) {

    //     if(response.data.accessToken)
    //     {const token = {
    //       token:response.data.accessToken,
    //       user: response.data.user
    //     };
    //     const lang = response.data?.user?.interfaceLanguage;
    //     storeCookies("user_token", token);
    //     storeCookies("user_lang",lang=="FR"?"fr":"en" );
    //     dispatch(changeLang(lang=="FR"?"fr":"en"));

    //     setLoading(false)
    //    navigate("/admin/events");}
    //    else{
    //     setLoading(false)
    //      message.error(response.data.message)
    //    }

    //   }
    // })
    // .catch((error) => {
    //   setLoading(false)
    //   message.error(error.response.data.message)
    // });
  }

  return (
    <Layout className='login-root'>
      <Row className='login-input-row'>
        <Col className='login-image'>
          <Image
            className='logo-image'
            width={500}
            preview={false}
            src={SideLogo}
          />
        </Col>
        <Col className='login-input-col'>
          <Layout className='login-input-root'>
            <div className='footlight-logo'>
              {/* <Image
        className="logo-image"
    width={50}
    preview={false}
    src={SideLogo}
  /> */}
              <div className='login-center-text text-center'>Welcome back</div>
              <div className='footlight-text-logo'>Login to your account</div>
              <div className='login-frame-icon'>
                <Image
                  className='logo-image'
                  width={200}
                  preview={false}
                  src={LoginFrame}
                />
              </div>
            </div>
            <div className='login-div'>
              <Form
                onFinish={handleLoginSubmit}
                className='login-form'
                form={form}
              >
                <div>
                  <div className='login-label'>{'EMAIL'}</div>
                  <Form.Item
                    name={'email'}
                    rules={[{ required: true, message: 'Please input your email' }]}
                  >
                    <Input
                      className='login-input'
                      placeholder={' '}
                      type={'email'}
                    />
                  </Form.Item>
                </div>
                {isOtp && (
                  <div>
                    <div className='login-label'>{'OTP'}</div>
                    <Form.Item
                      name={'otp'}
                      rules={[{ required: isOtp ? true : false, message: 'Please input  Otp' }]}
                    >
                      <Input
                        className='login-input'
                        placeholder={' '}
                        type={'number'}
                      />
                    </Form.Item>
                  </div>
                )}
                <Form.Item className='form-item-button'>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='button-login'
                  >
                    {isOtp ? 'Login' : 'Generate OTP'}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Layout>
        </Col>
      </Row>
      {loading && <Spinner />}
    </Layout>
  )
}

export default Login
