import React, { useState, useEffect } from 'react'
import { Layout, Card, Avatar, Button, Row, Col, message, Image } from 'antd'

import './ProfileVerification.scss'
import { RightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import moment from 'moment'

import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { userApprove, userReject } from '../UserManagement/services'
import Spinner from '../../components/spinner/Spinner'

import { getUrlForImage } from '../../utils/imageHelper'

const ProfileDetails = function ({ detailData, selectedType, goBack }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {}, [])
  const deactvateUser = async (id) => {
    setLoading(true)
    const deactivate = await userReject(id)
    setLoading(false)
    message.success('Rejected Profile')
  }
  const approveUser = async (id) => {
    setLoading(true)
    const deactivate = await userApprove(id)
    setLoading(false)
    message.success('Approved Profile')
  }
  return (
    <Layout className='abuse-details'>
      {detailData && (
        <>
          <Row justify='space-between'>
            <Col style={{ display: 'flex' }}>
              {' '}
              <ArrowLeftOutlined
                className='back-arrow'
                onClick={() => goBack(false)}
              />
              <div>
                <div className='profile-subhead'>Profile Verification of</div>
                <div className='profile-head'>{detailData?.first_name + ' ' + detailData?.last_name}</div>
              </div>
            </Col>

            <Col>
              <Button
                className='abuse-btn deact-btn'
                onClick={() => approveUser(detailData.user_id)}
              >
                Approve Profile
              </Button>
              <Button
                className='abuse-btn deact-btn'
                danger
                onClick={() => deactvateUser(detailData.user_id)}
              >
                Reject Profile
              </Button>
              <Button className='abuse-btn message-btn'>Message User</Button>
            </Col>
          </Row>

          <Card
            className='abused-card'
            title={<div className='card-title'>Profile Verification</div>}
            bordered={false}
            style={{ width: 300 }}
          >
            <Row>
              <Col>
                <Avatar
                  size={60}
                  src={
                    detailData?.verification_photos &&
                    detailData?.verification_photos.length > 0 &&
                    getUrlForImage(detailData?.verification_photos[0]?.photo_url, { width: 60 })
                  }
                />
              </Col>
              <Col className='abused-name-section'>
                <div className='abused-profile'>
                  <div className='title'>Name:</div>
                  <div className='title-value view-profile'>
                    {detailData?.first_name + ' ' + detailData?.last_name}
                    <div className='title-value'>
                      {' '}
                      <a onClick={() => navigate(`/admin/user-details/${detailData.user_id}`)}>View profile</a>
                    </div>{' '}
                  </div>
                </div>
                <div className='abused-profile'>
                  <div className='title'>Submitted Date</div>
                  <div className='title-value'>{moment(detailData.created_on).format('DD MMM, YYYY')}</div>
                </div>
                {/* <div className="abused-profile">
                                <div className="title">{selectedType == "Reported" ? "Reported By:" : "Blocked By:"}</div>
                                <div className="title-value">{detailData.user?.first_name + " " + detailData.user?.last_name}  <a onClick={() => navigate(`/admin/user-details/${detailData.user?.id}`)}>View profile</a></div>
                            </div> */}
                <div className='abused-profile'>
                  <div className='title'>Profile Images:</div>
                  <Row>
                    {detailData &&
                      detailData.verification_photos.map((item) => (
                        <Col
                          key={item.id}
                          style={{ margin: '10px' }}
                        >
                          {item && item.photo_url ? (
                            <Image
                              style={{ objectFit: 'cover' }}
                              width={100}
                              height={100}
                              src={getUrlForImage(item.photo_url, { width: 100, height: 100 })}
                            />
                          ) : null}
                        </Col>
                      ))}
                  </Row>
                </div>
              </Col>
            </Row>
          </Card>
        </>
      )}
      {loading && <Spinner />}
    </Layout>
  )
}
export default ProfileDetails
