import React, { useState, useEffect, useCallback } from 'react'
import { Layout, Button, Modal, message } from 'antd'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import './DynamicContent.scss'
import PageHeader from '../../components/spinner/pageHeader/PageHeader'

import {
  getFAQ,
  getResourcesAll,
  updateFaq,
  updateResource,
  deleteFaq,
  deleteResource,
  updateOrdinalResource,
  updateOrdinalFaq
} from './services'
import Spinner from '../../components/spinner/Spinner'
import AddFaqModal from './AddFaqModal'
import ContentEdit from './contentEdit'
import { Card } from './Card'

const { confirm } = Modal

let saveWarning = true
const DynamicContent = function () {
  const [contentList, setContentList] = useState([])
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedType, setSelectedType] = useState('FAQ')
  const [totalPage, setTotalPage] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)
  const [showAddModal, setShowAddModal] = useState(false)
  const [detailData, setDetailData] = useState()
  const [sortKey, setSortKey] = useState('created_on')
  const [sortValue, setSortValue] = useState('desc')
  const [seletedContent, setSeletedContent] = useState('')
  const [selectedPanel, setSelectedPanel] = useState('')

  useEffect(() => {
    getFaq(1)
  }, [])
  const updateFaqOrdinal = () => {
    let currentContent = [...contentList]
    contentList.map((item, key) => {
      currentContent[key].ordinal = key + 1
    })
    updateOrdinalFaq(currentContent).then((res) => {
      if (res.success) {
        message.success('Successfully updated')
      }
    })
  }
  const updateResourceOrdinal = () => {
    let currentContent = [...contentList]
    contentList.map((item, key) => {
      currentContent[key].ordinal = key + 1
    })
    updateOrdinalResource(currentContent).then((res) => {
      if (res.success) {
        message.success('Successfully updated')
      }
    })
  }
  const onPanelClick = (item) => {
    if (selectedPanel === item.id) {
      setSelectedPanel('')
    } else {
      setSelectedPanel(item.id)
    }
  }
  const onSearch = (value) => {
    getFaq(1, value)
  }
  const getFaq = async (page = 1, searchValue = '', sort = sortValue, sortkey = sortKey) => {
    setSortKey(sortkey)
    setSortValue(sort)
    setLoading(true)
    const Data = await getFAQ(page, searchValue, sort, sortkey)
    setLoading(false)
    setContentList(Data ? Data.data.data.sort((a, b) => a.ordinal - b.ordinal) : [])
    setTotalPage(Data ? Data.data.data.total : 1)
  }
  const getResources = async (page = 1, searchValue = '', sort = sortValue, sortkey = sortKey) => {
    setSortKey(sortkey)
    setSortValue(sort)
    setLoading(true)
    const Data = await getResourcesAll(page, searchValue, sort, sortkey)
    setLoading(false)
    console.log(Data.data)
    setContentList(Data ? Data.data.data.sort((a, b) => a.ordinal - b.ordinal) : [])
    setTotalPage(Data ? Data.data.data.total : 1)
  }

  const succesAdd = () => {
    setShowAddModal(false)
    if (selectedType == 'FAQ') getFaq(1, '')
    else getResources()
  }
  const editContent = (item) => {
    setSeletedContent(item)
    setIsModalVisible(true)
  }
  const deleteConfirm = async (item) => {
    if (selectedType == 'FAQ') {
      await deleteFaq({ id: item.id })
    } else {
      await deleteResource({ id: item.id })
    }
    succesAdd()
    message.success('Successfully deleted')
  }
  const deleteContent = async (item) => {
    confirm({
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleOutlined />,
      content: ' This action cannot be undone.',

      onOk() {
        deleteConfirm(item)
      },

      onCancel() {
        console.log('Cancel')
      }
    })
  }
  const onUpdatecontent = async () => {
    if (selectedType == 'FAQ') {
      await updateFaq(seletedContent)
    } else {
      await updateResource(seletedContent)
    }
    message.success('Successfully updated')
    setIsModalVisible(false)
    setSeletedContent({})
    succesAdd()
  }
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    // console.log('gggg', dragIndex, hoverIndex);
    setContentList((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    )
    if (saveWarning) {
      message.info('Please save once you finish reordering.')
    }
    saveWarning = false
  }, [])
  const onChangeTabView = (tab) => {
    setSelectedType(tab)
  }
  const saveOrdinal = () => {
    if (selectedType === 'FAQ') {
      updateFaqOrdinal(contentList)
    } else {
      updateResourceOrdinal(contentList)
    }
  }
  const onAddNewContent = () => {
    setShowAddModal(true)
  }
  const onSaveReorder = () => {
    saveOrdinal()
  }

  return (
    <Layout className='dashboard-layout-home'>
      <PageHeader
        heading='Dynamic Content'
        onSearch={onSearch}
      />

      <div className='profile-body'>
        <div className='header-section'>
          <div>
            <div className='profile-head'>FAQ and Resources</div>
            <div className='profile-subhead'>Add, edit and delete FAQs and other resources.</div>
          </div>
          <div>
            <Button
              type='text'
              className='add-faq-btn'
              onClick={() => onAddNewContent(true)}
            >
              <span className='span-content'>
                <PlusOutlined />
                {selectedType == 'FAQ' ? 'Add FAQ' : 'Add Resources'}
              </span>
            </Button>
            <Button
              type='primary'
              className={selectedType == 'FAQ' ? 'report-btn active-btn' : 'report-btn'}
              onClick={() => {
                onChangeTabView('FAQ')
                getFaq()
              }}
            >
              FAQ
            </Button>
            <Button
              type='primary'
              className={selectedType == 'Resources' ? 'report-btn active-btn' : 'report-btn'}
              onClick={() => {
                onChangeTabView('Resources')
                getResources()
              }}
            >
              Resources
            </Button>
          </div>
        </div>
        <div className='swipe-content'>
          <DndProvider backend={HTML5Backend}>
            <div className='table-section'>
              {contentList.map((item, index) => (
                <Card
                  item={item}
                  selectedType={selectedType}
                  editContent={editContent}
                  deleteContent={deleteContent}
                  onPanelClick={onPanelClick}
                  selectedPanel={selectedPanel}
                  key={item.id}
                  index={index}
                  id={item.id}
                  moveCard={moveCard}
                />
              ))}
            </div>
          </DndProvider>
        </div>
        <div className='drag-save'>
          <Button
            type='primary'
            className='ant-btn ant-btn-primary secondary-btn'
            onClick={onSaveReorder}
          >
            Save
          </Button>
        </div>
      </div>

      {loading && <Spinner />}
      {showAddModal && (
        <AddFaqModal
          isModalVisible={showAddModal}
          setIsModalVisible={setShowAddModal}
          selectedType={selectedType}
          succesAdd={succesAdd}
        />
      )}
      {isModalVisible && (
        <ContentEdit
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setSeletedContent={setSeletedContent}
          seletedContent={seletedContent}
          onUpdatecontent={onUpdatecontent}
          selectedType={selectedType}
        />
      )}
    </Layout>
  )
}
export default DynamicContent
