import React, { useState, useEffect } from 'react'
import { Layout, Card, Avatar, Button, Row, Col, message, Table } from 'antd'

import './AbuseMngmt.scss'
import { RightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import moment from 'moment'

import { useNavigate } from 'react-router-dom'
import { deactivateBlockedUser, deactivateUser } from './services'
import { userActivate } from '../UserManagement/services'

import { getUrlForImage } from '../../utils/imageHelper'

const AbuseDetails = function ({ detailData, selectedType, goBack, setDetailData }) {
  const navigate = useNavigate()

  const deactvateUser = async (id) => {
    const deactivate = await deactivateUser(id)
    message.success(deactivate?.data?.msg)
    let currentData = { ...detailData }
    currentData.account_status = 'DELETED'
    setDetailData(currentData)
  }
  const actvateUser = async (id) => {
    const activate = await userActivate(id)
    message.success(activate?.data?.msg)
    let currentData = { ...detailData }
    currentData.account_status = 'ACTIVE'
    setDetailData(currentData)
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className='user_item'>
          <label>{selectedType === 'Reported' ? 'Reported By' : 'Blocked By'}</label>
          <div className='value'>{record.first_name + ' ' + record.last_name}</div>
        </div>
      )
    },
    {
      title: 'date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <div className='user_item'>
          <label>{selectedType === 'Reported' ? 'Reported On' : 'Blocked on'}</label>
          <div className='value'>{moment(record.blocked_on || record.reported_on).format('DD MMM, YYYY')}</div>
        </div>
      )
    },
    {
      title: 'active',
      dataIndex: 'note',
      key: 'note',
      render: (text, record) => (
        <div className='user_item'>
          <label>Note</label>
          <div className='value'>{text || '-'}</div>
        </div>
      )
    },

    {
      title: 'Social',
      dataIndex: 'social',
      key: 'social',
      render: (text, record) => (
        <div className='user_item'>
          <a onClick={() => navigate(`/admin/user-details/${record.id}`)}>View profile</a>
        </div>
      )
    }
  ]
  return (
    <Layout className='abuse-details'>
      {detailData && (
        <>
          <Row justify='space-between'>
            <Col style={{ display: 'flex' }}>
              {' '}
              <ArrowLeftOutlined
                className='back-arrow'
                onClick={() => goBack(false)}
              />
              <div>
                <div className='profile-subhead'>{selectedType == 'Reported' ? 'Reported' : 'Blocked'} for Abuse</div>
                <div className='profile-head'>{detailData?.first_name + ' ' + detailData?.last_name}</div>
              </div>
            </Col>

            <Col>
              {detailData.account_status === 'ACTIVE' ? (
                <Button
                  className='abuse-btn deact-btn'
                  onClick={() => deactvateUser(detailData.reported_user_id || detailData.blocked_user_id)}
                >
                  Deactivate Account
                </Button>
              ) : (
                <Button
                  className='abuse-btn deact-btn'
                  onClick={() => actvateUser(detailData.reported_user_id || detailData.blocked_user_id)}
                >
                  Activate Account
                </Button>
              )}
              <Button
                className='abuse-btn message-btn'
                onClick={() => navigate(`/admin/messaging?search=${detailData?.first_name}`)}
              >
                Message User
              </Button>
            </Col>
          </Row>

          <Card
            className='abused-card'
            title={
              <div className='card-title'>
                {selectedType == 'Reported' ? 'Who Was Reported: ' : 'Who Was Blocked: '}
                {selectedType == 'Reported'
                  ? detailData?.first_name + ' ' + detailData?.last_name
                  : detailData?.first_name + ' ' + detailData?.last_name}
              </div>
            }
            bordered={false}
            style={{ width: 300 }}
          >
            <Row>
              <Col>
                <Avatar
                  size={60}
                  src={
                    selectedType === 'Reported'
                      ? detailData?.user_photos?.length > 0
                        ? getUrlForImage(detailData?.user_photos[0].photo_url, { width: 60 })
                        : '/sample_img.png'
                      : detailData?.user_photos?.length > 0
                        ? getUrlForImage(detailData?.user_photos[0].photo_url, { width: 60 })
                        : '/sample_img.png'
                  }
                />
              </Col>
              <Col className='abused-name-section'>
                <div className='abused-profile'>
                  <div className='title'>Name:</div>
                  <div className='title-value'>
                    {selectedType == 'Reported'
                      ? detailData?.first_name + ' ' + detailData?.last_name
                      : detailData?.first_name + ' ' + detailData?.last_name}{' '}
                    <a
                      onClick={() =>
                        navigate(`/admin/user-details/${detailData.reported_user_id || detailData.blocked_user_id}`)
                      }
                    >
                      View profile
                    </a>
                  </div>
                </div>
                {/* <div className="abused-profile">
                                <div className="title">{selectedType == "Reported" ? "Reported By:" : "Blocked By:"}</div>
                                <div className="title-value">{detailData.user?.first_name + " " + detailData.user?.last_name}  <a onClick={() => navigate(`/admin/user-details/${detailData.user?.id}`)}>View profile</a></div>
                            </div> */}
                <div className='abused-profile'>
                  <div className='title'>Status:</div>
                  <div className='title-value'>{detailData.account_status} </div>
                </div>
              </Col>
            </Row>
          </Card>
          <div>
            <div className='block_detail'>
              <Table
                dataSource={detailData.reported_by || detailData.blocked_by}
                columns={columns}
                showHeader={false}
              />
            </div>
          </div>
        </>
      )}
    </Layout>
  )
}
export default AbuseDetails
