import { Modal, Button } from 'antd'
import EditorDesc from './EditorDesc'

const AddAnnounce = ({
  isModalVisible,
  setIsModalVisible,
  htmlFile,
  setHtmlFile,
  onAddAnnouncement,
  setTitle,
  title
}) => {
  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setTitle('')
    setHtmlFile('')
  }

  return (
    <Modal
      title='Create Announcement'
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className='add-modal'
      okText='Done'
      footer={false}
    >
      <div>
        <input
          placeholder='Title'
          className='anounce_title'
          onChange={(e) => {
            setTitle(e.target.value)
          }}
          value={title}
        />
      </div>
      <div className='msg_wrap'>
        <EditorDesc
          formName='desc'
          htmlFile={htmlFile}
          setHtmlFile={setHtmlFile}
        />
      </div>
      <Button
        type='primary'
        disabled={!htmlFile || !title}
        htmlType='submit'
        className='button-login'
        onClick={() => {
          onAddAnnouncement()
        }}
      >
        Send
      </Button>
    </Modal>
  )
}
export default AddAnnounce
