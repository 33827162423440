import { serviceHandler } from '../../utils/serviceHandler'

export const fetchPendingUsers = (page = 1, value = '', sort = 'desc', sortKey, limit = 20) => {
  return serviceHandler({
    url: `/user`,
    methodType: 'get',

    params: {
      page: page,
      searchKey: 'account_status',
      searchValue: 'PENDING',
      sortKey,
      sortOrder: sort ? sort : 'desc',
      limit
    }
  })
}

export const approvePendingUser = (id) => {
  return serviceHandler({
    url: `/user/activate-account/${id}`,
    methodType: 'put'
  })
}

export const rejectPendingUser = (id, payload) => {
  return serviceHandler({
    url: `user/reject-account/${id}`,
    methodType: 'put',
    payload
  })
}
