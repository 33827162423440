import React, { useState, useEffect, useRef } from 'react'
import { Layout, Button, Avatar, Dropdown, Table, Row, Col, Menu, message } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import './AbuseMngmt.scss'
import PageHeader from '../../components/spinner/pageHeader/PageHeader'
import ChatIcon from '../../assets/chat.svg'
import TickIcon from '../../assets/tick.svg'
import CloseIcon from '../../assets/cross.svg'
import SortIcon from '../../assets/sort.svg'
import FilterIcon from '../../assets/filter.svg'
import AbuseDetails from './AbuseDetails'
import {
  blockedsers,
  blockedUserExport,
  deactivateUser,
  reportedSingleUser,
  ReportedUserExport,
  reportedUsers
} from './services'
import moment from 'moment'
import Spinner from '../../components/spinner/Spinner'
import { userActivate } from '../UserManagement/services'
import { CSVLink } from 'react-csv'

import { getUrlForImage } from '../../utils/imageHelper'

const AbuseManagement = function () {
  const [blockedList, setBlockedList] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedType, setSelectedType] = useState('Blocked')
  const [totalPage, setTotalPage] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)
  const [isDetail, setIsDetail] = useState(false)
  const [detailData, setDetailData] = useState()
  const [sortKey, setSortKey] = useState('created_on')
  const [sortValue, setSortValue] = useState('desc')
  const [searchValue, setSearchValue] = useState('')
  const [csvData, setCsvData] = useState([])
  const [curremtTime, setCurrentTime] = useState()
  const csvRef = useRef(null)

  useEffect(() => {
    getUsers(1)
    setSelectedType('Blocked')
    var today = new Date(),
      date =
        today.getFullYear() +
        '' +
        (today.getMonth() + 1) +
        '' +
        today.getDate() +
        today.getHours() +
        '' +
        today.getMinutes()
    setCurrentTime(date)
  }, [])

  const onSearch = (value, type) => {
    setSearchValue(value)
    if (type === 'Blocked') getUsers(1, value)
    else getReportedUsers(1, value)
  }
  const getUsers = async (page = 1, searchValue = '', sort = sortValue, sortkey = sortKey) => {
    setSortKey(sortkey)
    setSortValue(sort)
    setLoading(true)
    const Data = await blockedsers(page, searchValue, sort, sortkey)
    setLoading(false)
    setBlockedList(Data ? Data.data.data.blockedUsers : [])
    setTotalPage(Data ? Data.data.data.total : 1)
  }
  const getReportedUsers = async (page = 1, searchValue = '', sort = sortValue, sortkey = sortKey) => {
    setSortKey(sortkey)
    setSortValue(sort)
    setLoading(true)
    const Data = await reportedUsers(page, searchValue, sort, sortkey)
    setLoading(false)
    setBlockedList(Data ? Data.data.data.reportedUsers : [])
    setTotalPage(Data ? Data.data.data.total : 1)
  }

  const deactivateUsers = async (id, event, type) => {
    event.stopPropagation()
    const deactivate = type == 'ACTIVE' ? await deactivateUser(id) : await userActivate(id)
    message.success(deactivate?.data?.msg)
    if (selectedType == 'Reported') {
      getReportedUsers(1, '')
    } else {
      getUsers(1, '')
    }
  }

  const menu = (
    <Menu
      onClick={(e) =>
        e.key === '0'
          ? selectedType == 'Reported'
            ? getReportedUsers(1, searchValue, 'desc')
            : getUsers(1, searchValue, 'desc')
          : selectedType == 'Reported'
            ? getReportedUsers(1, searchValue, 'asc')
            : getUsers(1, searchValue, 'asc')
      }
      items={[
        {
          label: 'Descending',
          key: '0'
        },
        {
          label: 'Ascending',
          key: '1'
        }
      ]}
    ></Menu>
  )
  const eventTableHeader = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (e, record) => (
        <Row className='image-name'>
          <Col flex='0 1 50px'>
            <Avatar
              size={70}
              src={
                selectedType === 'Reported'
                  ? record?.user_photos?.length > 0
                    ? getUrlForImage(record.user_photos[0].photo_url, { width: 70 })
                    : '/sample_img.png'
                  : record?.user_photos?.length > 0
                    ? getUrlForImage(record.user_photos[0].photo_url, { width: 70 })
                    : '/sample_img.png'
              }
              alt=''
            />
          </Col>
          <Col
            flex='1 1 200px'
            className='name-col'
          >
            <div className='table-name-head'>Name</div>

            <div className='user-name-col'>
              {selectedType == 'Reported'
                ? record?.first_name + ' ' + record?.last_name
                : record?.first_name + ' ' + record?.last_name}
            </div>
          </Col>
        </Row>
      )
    },
    // {
    //   title: "",
    //   dataIndex: "startDate",
    //   key: "startDate",
    //   width: 200,
    //   render: (e, record) => (
    //     <div>
    //       <div className="table-name-head">
    //         {selectedType == "Reported" ? "Reported Date" : "Blocked Date"}
    //       </div>

    //       <div
    //         className="user-name-col"
    //       >
    //         {moment(record.last_blocked_on)
    //           .format("DD MMM, YYYY")}
    //       </div>
    //     </div>
    //   )
    // },
    {
      title: '',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      render: (e, record) => (
        <div>
          <div className='table-name-head'>{selectedType == 'Reported' ? 'Reported By' : 'Blocked By'}</div>

          <div className='user-name-col'>
            {/* {record.user?.first_name + " " + record.user?.last_name} */}
            {/* {selectedType == "Reported" ? record.reported_user?.first_name + " " + record.reported_user?.last_name:
             record.blocked_user?.first_name + " " + record.blocked_user?.last_name} */}
            {((selectedType == 'Reported' ? record?.reported_by?.length : record?.blocked_by?.length) || 0) + ' Users'}
          </div>
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      render: (e, record) => (
        <div>
          <div className='table-name-head'>{selectedType == 'Reported' ? 'Last Reported' : 'Last Blocked'}</div>

          <div className='user-name-col'>
            {selectedType == 'Reported'
              ? moment(record.last_reported_on).format('DD MMM, YYYY')
              : moment(record?.last_blocked_on).format('DD MMM, YYYY')}
          </div>
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      render: (e, record) => (
        <div>
          <div className='table-name-head'>Status</div>

          <div className='user-name-col'>{record?.account_status}</div>
        </div>
      )
    },

    {
      title: '',
      dataIndex: 'hasLegacyCapability',
      key: 'hasLegacyCapability',
      width: 200,
      render: (e, record) => (
        <div>
          <Button
            type='primary'
            danger={record?.account_status == 'ACTIVE' ? true : false}
            className='delete-btn'
            onClick={(event) =>
              deactivateUsers(record.reported_user_id || record.blocked_user_id, event, record?.account_status)
            }
          >
            {record?.account_status == 'ACTIVE' ? 'Deactivate User' : 'Activate User'}
          </Button>
        </div>
      )
    }
  ]

  const exportCsv = async () => {
    setLoading(true)
    const Data = selectedType == 'Blocked' ? await blockedUserExport() : await ReportedUserExport()
    setCsvData(Data.data.data.result)
    setTimeout(() => csvRef.current.link.click(), 4000)

    setLoading(false)
  }
  const headers = [
    { label: 'Blocked By', key: 'Blocked By' },
    { label: 'Blocked On', key: 'Blocked On' },
    { label: 'Blocked User', key: 'Blocked User' }
  ]

  const headersReport = [
    { label: 'Reported By', key: 'Reported By' },
    { label: 'Reported On', key: 'Reported On' },
    { label: 'Reported User', key: 'Reported User' }
  ]

  return (
    <Layout className='dashboard-layout-home'>
      <PageHeader
        heading='Abuse Management'
        isSearch
        onSearch={onSearch}
        selectedType={selectedType}
        className={isDetail ? 'opacity-0' : 'opacity-1'}
      />
      {!isDetail ? (
        <div className='profile-body'>
          <div className='header-section'>
            <div style={{ display: 'flex' }}>
              <div>
                <div className='profile-head'>
                  {selectedType == 'Blocked' ? 'Blocked ' : 'Report '} Abuse Management
                </div>
                <div className='profile-subhead'>See the list of users who have been reported.</div>
              </div>
              <Button
                type='primary'
                className='secondary-btn'
                onClick={() => exportCsv()}
              >
                Export
              </Button>
              <CSVLink
                className='csv-file'
                ref={csvRef}
                data={csvData}
                headers={selectedType == 'Reported' ? headers : headersReport}
                filename={
                  selectedType == 'Reported'
                    ? `"Dateability_ReportedUsers_${curremtTime}.csv"`
                    : `"Dateability_BlockedUsers_${curremtTime}"`
                }
              >
                Download me
              </CSVLink>
            </div>
            <div>
              <Button
                type='primary'
                className={selectedType == 'Blocked' ? 'report-btn active-btn' : 'report-btn'}
                onClick={() => {
                  setSelectedType('Blocked')
                  getUsers()
                }}
              >
                Blocked
              </Button>
              <Button
                type='primary'
                className={selectedType == 'Reported' ? 'report-btn active-btn' : 'report-btn'}
                onClick={() => {
                  setSelectedType('Reported')
                  getReportedUsers()
                }}
              >
                Reported
              </Button>
            </div>
          </div>

          <div className='table-section'>
            <div className='table-header'>
              <div className='table-header-name'>{selectedType === 'Blocked' ? 'Blocked Users' : 'Reported Users'}</div>
              <div className='sort-filter'>
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  placement='bottomCenter'
                >
                  <div>
                    <img
                      src={SortIcon}
                      className='action-icon'
                    />{' '}
                    Sort
                  </div>
                </Dropdown>

                {/* <div><img src={SortIcon} className="action-icon" /> Sort</div> */}
                {/* <div><img src={FilterIcon} className="action-icon" /> Filter</div> */}
              </div>
            </div>
            <Table
              dataSource={blockedList}
              columns={eventTableHeader}
              className={'event-table'}
              showHeader={false}
              // scroll={{ x: 1000, y: "calc(100% - 60px)" }}
              rowKey={(record) => record.id}
              pagination={{
                onChange: (page) => {
                  setDefaultPage(page)
                  if (selectedType == 'Reported') getReportedUsers(page)
                  else getUsers(page)
                  // getEvents(page);
                },
                current: defaultPage,
                pageSize: 20,
                total: totalPage,
                hideOnSinglePage: true,
                showSizeChanger: false,
                position: ['topRight']
              }}
              rowClassName={(record, index) => 'disable-row'}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    event.stopPropagation()
                    // reportedSingleUser(record.id)
                    setDetailData(record)
                    setIsDetail(true)
                    // navigate(`/admin/add-event/?id=${record.id}`);
                  } // click row
                }
              }}
            />
          </div>
        </div>
      ) : (
        <AbuseDetails
          detailData={detailData}
          selectedType={selectedType}
          goBack={setIsDetail}
          setDetailData={setDetailData}
        />
      )}
      {loading && <Spinner />}
    </Layout>
  )
}
export default AbuseManagement
