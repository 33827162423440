import { Button } from 'antd'
import React from 'react'
import KpiCards from './kpiCards'
import { user } from '../children/constants'

const UserKpi = (props) => {
  const { userList, setUserPeriod, userPeriod } = props
  return (
    <>
      <div className='sub_head'>
        <h2>User KPI</h2>
        <div>
          <Button
            type='primary1'
            className={userPeriod === 'WEEKLY' ? 'active-btn' : 'report-btn'}
            onClick={() => {
              setUserPeriod('WEEKLY')
            }}
          >
            Weekly
          </Button>
          <Button
            type='primary1'
            className={userPeriod === 'MONTHLY' ? 'active-btn' : 'report-btn'}
            onClick={() => {
              setUserPeriod('MONTHLY')
            }}
          >
            Monthly
          </Button>
          <Button
            type='primary1'
            className={userPeriod === 'YEARLY' ? 'active-btn' : 'report-btn'}
            onClick={() => {
              setUserPeriod('YEARLY')
            }}
          >
            Yearly
          </Button>
          <Button
            type='primary1'
            className={userPeriod === 'ALL' ? 'active-btn' : 'report-btn'}
            onClick={() => {
              setUserPeriod('ALL')
            }}
          >
            All
          </Button>
        </div>
      </div>
      <div className='kpiwrap'>
        {user.map((item) => {
          return (
            <KpiCards
              count={userList[item.key]}
              label={item.label}
            />
          )
        })}
      </div>
    </>
  )
}
export default UserKpi
