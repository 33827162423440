import React, { useState, useEffect, useCallback } from 'react'
import { Layout, Avatar, Dropdown, Table, Row, Col, Menu } from 'antd'
import moment from 'moment'

import { fetchPendingUsers } from './services'
import PageHeader from '../../components/spinner/pageHeader/PageHeader'
import SortIcon from '../../assets/sort.svg'
import PendingAccountDetails from './PendingAccountDetails'
import AccountReviewButtons from './AccountReviewButtons'

import Spinner from '../../components/spinner/Spinner'
import { UserStatusTag } from '../../components/UserStatusTag'

import { getUrlForImage } from '../../utils/imageHelper'

import './PendingAccounts.scss'

const pageSize = 20

const PendingAccounts = function () {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)
  const [isDetail, setIsDetail] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [sortKey, setSortKey] = useState('created_on')
  const [sortValue, setSortValue] = useState('desc')
  const [searchValue, setSearchValue] = useState('')

  const getPendingUsers = useCallback(
    async (page = 1, searchValue = '', sort = sortValue, sortkey = sortKey) => {
      setSortKey(sortkey)
      setSortValue(sort)
      setLoading(true)

      const response = await fetchPendingUsers(page, searchValue, sort, sortkey, pageSize)

      setLoading(false)
      setUsers(response?.data?.data?.users || [])
      setTotalRecords(response?.data?.data?.total || 1)
    },
    [sortKey, sortValue]
  )

  useEffect(() => {
    // load page 1
    getPendingUsers()
  }, [getPendingUsers])

  const onSearch = (value, type) => {
    setSearchValue(value)
    getPendingUsers(1, value)
  }

  const onComplete = useCallback(() => {
    setIsDetail(false)
    setSelectedUser(null)
    getPendingUsers()
  }, [setIsDetail, setSelectedUser, getPendingUsers])

  const menu = (
    <Menu
      onClick={(e) =>
        e.key === '0' ? getPendingUsers(1, searchValue, 'desc') : getPendingUsers(1, searchValue, 'asc')
      }
      items={[
        {
          label: 'Descending',
          key: '0'
        },
        {
          label: 'Ascending',
          key: '1'
        }
      ]}
    ></Menu>
  )
  const eventTableHeader = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (e, record) => (
        <Row
          className='image-name'
          onClick={() => {
            setSelectedUser(record)
            setIsDetail(true)
          }}
        >
          <Col flex='0 1 50px'>
            <Avatar
              size={70}
              src={
                record?.UserPhotos?.length > 0
                  ? getUrlForImage(record.UserPhotos[0].photo_url, { width: 70 })
                  : '/sample_img.png'
              }
              alt='Photo'
            />
          </Col>
          <Col
            flex='1 1 200px'
            className='name-col'
          >
            <div className='table-name-head'>Name</div>

            <div className='user-name-col'>{record?.first_name + ' ' + record?.last_name}</div>
            <div className='user-email-col'>{record?.email}</div>
          </Col>
        </Row>
      )
    },
    {
      title: '',
      dataIndex: 'createdOn',
      key: 'createdOn',
      width: 150,
      render: (e, record) => (
        <div>
          <div className='table-name-head'>Registered On</div>

          <div className='user-name-col'>{moment(record.created_on).format('DD MMM, YYYY')}</div>
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'emailVerifiedOn',
      key: 'emailVerifiedOn',
      width: 150,
      render: (e, record) => (
        <div>
          <div className='table-name-head'>Email Verified On</div>

          <div className='user-name-col'>
            {record?.email_verified_on ? moment(record.email_verified_on).format('DD MMM, YYYY') : 'Never'}
          </div>
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'UserInfo.updated_on',
      key: 'UserInfo.updated_on',
      width: 150,
      render: (e, record) => (
        <div>
          <div className='table-name-head'>Profile last updated</div>

          <div className='user-name-col'>
            {record.UserInfo?.updated_on
              ? moment(record.UserInfo?.updated_on).format('DD MMM, YYYY')
              : 'No user info provided yet.'}
          </div>
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'totalLikesGiven',
      key: 'totalLikesGiven',
      width: 100,
      render: (e, record) => (
        <div>
          <div className='table-name-head'>Likes Given</div>

          <div className='user-name-col'>{record.total_likes_given || 0}</div>
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'accountStatus',
      key: 'accountStatus',
      width: 150,
      render: (e, record) => (
        <div>
          <div className='table-name-head'>Status</div>

          <div className='user-name-col'>
            <UserStatusTag
              size='large'
              status={record?.account_status}
            />
          </div>
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 200,
      render: (e, record) => (
        <AccountReviewButtons
          key={record.id}
          userDetail={record}
          onComplete={onComplete}
        />
      )
    }
  ]

  return (
    <Layout className='dashboard-layout-home'>
      <PageHeader
        heading='Pending Accounts'
        isSearch
        onSearch={onSearch}
        className={isDetail ? 'opacity-0' : 'opacity-1'}
      />
      {!isDetail ? (
        <div className='profile-body'>
          <div className='header-section'>
            <div style={{ display: 'flex' }}>
              <div>
                <div className='profile-head'>Pending Accounts</div>
                <div className='profile-subhead'>
                  See the list of users who recently signed up and are not yet reviewed.
                </div>
              </div>
            </div>
          </div>

          <div className='table-section'>
            <div className='table-header'>
              <div className='table-header-name'>Pending Accounts {totalRecords > 1 ? `(${totalRecords})` : ''}</div>
              <div className='sort-filter'>
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  placement='bottom'
                >
                  <div>
                    <img
                      aria-label='Sort'
                      src={SortIcon}
                      className='action-icon'
                    />{' '}
                    Sort
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className='adjust-table-height'>
              <Table
                dataSource={users}
                columns={eventTableHeader}
                className={'event-table'}
                showHeader={false}
                // scroll={{ x: 1000, y: "calc(100% - 60px)" }}
                rowKey={(record) => record.id}
                pagination={{
                  onChange: (page) => {
                    setDefaultPage(page)
                    getPendingUsers(page)
                  },
                  current: defaultPage,
                  pageSize: pageSize,
                  total: totalRecords,
                  hideOnSinglePage: true,
                  showSizeChanger: false,
                  position: ['topRight']
                }}
                rowClassName={(record, index) => 'disable-row'}
              />
            </div>
          </div>
        </div>
      ) : (
        <PendingAccountDetails
          detailData={selectedUser}
          goBack={setIsDetail}
          onComplete={onComplete}
          setSelectedUser={setSelectedUser}
        />
      )}

      {loading && <Spinner />}
    </Layout>
  )
}
export default PendingAccounts
