import { serviceHandler } from '../../utils/serviceHandler'
import apiEndPoints from '../../utils/apiUrlConstants'

export const getUserList = (payload) => {
  return serviceHandler({
    url: `admin/users?period=${payload.period}`,
    methodType: 'get'
  })
}
export const getGrowth = (payload) => {
  return serviceHandler({
    url: `admin/user-growth?period=${payload.period}`,
    methodType: 'get'
  })
}
export const getActivity = (payload) => {
  return serviceHandler({
    url: `admin/user-activities?period=${payload.period}`,
    methodType: 'get'
  })
}
export const getDisability = (payload) => {
  return serviceHandler({
    url: `admin/disability?period=${payload.period}`,
    methodType: 'get'
  })
}
export const getGender = (payload) => {
  return serviceHandler({
    url: `admin/gender?period=${payload.period}`,
    methodType: 'get'
  })
}
export const getInterest = (payload) => {
  return serviceHandler({
    url: `admin/interests?period=${payload.period}`,
    methodType: 'get'
  })
}
export const getExportCSV = (payload) => {
  return serviceHandler({
    url: `/admin/export/user_stats`,
    methodType: 'get'
  })
}
export const getAgeRange = (payload) => {
  return serviceHandler({
    url: `admin/age-range-stats`,
    methodType: 'get'
  })
}
