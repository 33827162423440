import React, { useCallback } from 'react'
import { Button, Input } from 'antd'
import './PageHeader.scss'
import _debounce from 'lodash/debounce'

import { removeCookies, storeCookies } from '../../../utils/Utility'
import { useNavigate } from 'react-router-dom'

const { Search } = Input

const PageHeader = function (props) {
  const navigate = useNavigate()
  const { heading, className = 'opacity-1' } = props
  const searchResult = (query) => {
    props.onSearch(query, props.selectedType)
  }

  const onSearch = (value) => debounceFn(value)
  const debounceFn = useCallback(_debounce(searchResult, 1000), [])
  const logout = () => {
    removeCookies('user_token')
    storeCookies('user_token', null)
    navigate(`/`)
  }

  return (
    <div className='dashboard-header'>
      <div className='page-header'>{heading}</div>
      <div className={className}>
        {props.isSearch && (
          <Search
            placeholder='Input search text'
            allowClear
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        )}
      </div>
      <div>
        {/* <Dropdown overlay={menu} trigger={['click']}
         placement="topRight"
         arrow={{
         pointAtCenter: true,
         }}>

         <Space>
         <Avatar
         style={{backgroundColor: "#f56a00"}}
         src={"../../user-icon"}
         >
         </Avatar>

         </Space>

         </Dropdown> */}
        <Button
          onClick={() => logout()}
          className='logout-btn'
        >
          Logout
        </Button>
      </div>
    </div>
  )
}
export default PageHeader
