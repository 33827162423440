import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Avatar, Button, Layout, Table, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import PageHeader from '../../components/spinner/pageHeader/PageHeader'
import './userMngmt.scss'
import { searchForUsers, exportSearchedUsers } from './services'
import moment from 'moment'
import Spinner from '../../components/spinner/Spinner'
import { CSVLink } from 'react-csv'
import UserDetails from '../UserDetails'
import { UserStatusTag } from '../../components/UserStatusTag'

import { getUrlForImage } from '../../utils/imageHelper'

const pageSize = 20

const UserManagement = function (callback, deps) {
  const [userList, setUserList] = useState([])
  const [csvData, setCsvData] = useState([])
  const [totalRecords, setTotalRecords] = useState(1)
  const [defaultPage, setDefaultPage] = useState(1)
  const [loading, setLoading] = useState(false)

  const [userInfos, setUserInfos] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const csvRef = useRef(null)

  const handleSearch = useCallback(
    async (page = undefined) => {
      try {
        setLoading(true)
        const data = await searchForUsers(
          page || 1,
          pageSize,
          firstName.length > 0 ? firstName : undefined,
          lastName.length > 0 ? lastName : undefined,
          email.length > 0 ? email : undefined
        )

        setUserList(data ? data.data.data.users : [])
        setTotalRecords(data ? data.data.data.total : 1)
      } finally {
        setLoading(false)
      }
    },
    [email, firstName, lastName]
  )

  const doSearch = useCallback(async () => {
    setDefaultPage(1)
    return handleSearch(1)
  }, [handleSearch])

  useEffect(() => {
    doSearch().then()
  }, [doSearch])

  const searchOnEnter = async (evt) => {
    if (evt.code === 'Enter') {
      await doSearch()
    }
  }

  const showDetails = (record) => {
    setUserInfos(record.id)
  }

  const exportCsv = async () => {
    setLoading(true)
    try {
      const data = await exportSearchedUsers(
        firstName.length > 0 ? firstName : undefined,
        lastName.length > 0 ? lastName : undefined,
        email.length > 0 ? email : undefined
      )
      setCsvData(data.data.data.result)
      setTimeout(() => csvRef.current.link.click(), 4000)
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
      render: (text, record) => (
        <div className='img_sec'>
          <Avatar
            size={70}
            src={record.UserPhotos.length > 0 ? getUrlForImage(record.UserPhotos[0].photo_url) : '/sample_img.png'}
            alt=''
          />
        </div>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className='user_item'>
          <label>Name</label>
          <div className='value'>{record.first_name + ' ' + record.last_name}</div>
        </div>
      )
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div className='user_item'>
          <label>Status</label>
          <div className='value'>
            <UserStatusTag
              size='large'
              status={record.account_status}
            />
          </div>
        </div>
      )
    },
    {
      title: 'date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <div className='user_item'>
          <label>Sign up date</label>
          <div className='value'>{moment(record.created_on).format('DD MMM, YYYY')}</div>
        </div>
      )
    },
    {
      title: 'active',
      dataIndex: 'active',
      key: 'active',
      render: (text, record) => (
        <div className='user_item'>
          <label>Last Activity Date</label>
          <div className='value'>{moment(record.last_login_time).format('DD MMM, YYYY')}</div>
        </div>
      )
    },

    {
      title: 'Social',
      dataIndex: 'social',
      key: 'social',
      render: (text, record) => (
        <div className='user_item'>
          <label>Sign-in</label>
          <div className='value'>{record.social_media_type}</div>
        </div>
      )
    }
  ]

  const headers = [
    {
      label: 'Created on',
      key: 'created_on'
    },
    {
      label: 'First Name',
      key: 'first_name'
    },
    {
      label: 'Last Name',
      key: 'last_name'
    },
    {
      label: 'Status',
      key: 'account_status'
    },
    {
      label: 'id',
      key: 'id'
    },
    {
      label: 'Last login time',
      key: 'last_login_time'
    },
    {
      label: 'Email',
      key: 'email'
    }
  ]
  return (
    <Layout className='dashboard-layout-home'>
      <PageHeader
        heading={userInfos ? 'User Details' : 'Manage Users'}
        className={userInfos ? 'opacity-0' : 'opacity-1'}
      />
      {!userInfos ? (
        <>
          <div className='wrapper'>
            <div className='main_head'>
              <h2>User Management</h2>

              <CSVLink
                className='csv-file'
                ref={csvRef}
                data={csvData}
                headers={headers}
                filename={'Users.csv'}
              >
                Download me
              </CSVLink>
              <Button
                type='primary'
                className='secondary-btn'
                onClick={() => exportCsv()}
              >
                Export
              </Button>
            </div>

            <fieldset className='userSearchBox'>
              <label>First name</label>

              <input
                placeholder='First name'
                value={firstName}
                onChange={(evt) => setFirstName(evt.currentTarget.value)}
                onKeyUp={searchOnEnter}
              />

              <label>Last name</label>

              <input
                placeholder='Last name'
                value={lastName}
                onChange={(evt) => setLastName(evt.currentTarget.value)}
                onKeyUp={searchOnEnter}
              />

              <label>Email address</label>

              <input
                placeholder='Email address'
                value={email}
                onChange={(evt) => setEmail(evt.currentTarget.value)}
                onKeyUp={searchOnEnter}
              />

              <button
                style={{ marginRight: 10 }}
                onClick={() => doSearch()}
              >
                Search
              </button>

              <Tooltip title='Use % as a wildcard in search fields ("%mes" would match James)'>
                <QuestionCircleOutlined />
              </Tooltip>
            </fieldset>
            <div className='content_area'>
              <div className='header_sec'>
                <h2>Users ({totalRecords})</h2>
              </div>
              <div className='user_card'>
                <div className='user-management-table'>
                  <Table
                    dataSource={userList}
                    columns={columns}
                    showHeader={false}
                    rowKey={(record) => record.id}
                    pagination={{
                      onChange: (page) => {
                        setDefaultPage(page)
                        handleSearch(page).then()
                      },
                      current: defaultPage,
                      pageSize: pageSize,
                      total: totalRecords,
                      hideOnSinglePage: true,
                      showSizeChanger: false,
                      position: ['topRight']
                    }}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          showDetails(record)
                        } // click row
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {loading && <Spinner />}
        </>
      ) : (
        <UserDetails
          userInfoId={userInfos}
          setUserInfos={setUserInfos}
        />
      )}
    </Layout>
  )
}
export default UserManagement
