import { Table } from 'antd'
import React from 'react'

const GenderTable = (props) => {
  const { genderList } = props

  const columns = [
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender'
    },
    {
      title: 'Number of Users',
      dataIndex: 'count',
      key: 'count'
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      render(percent) {
        return percent + '%'
      }
    }
  ]
  return (
    <div className='dash-table'>
      <h3>Gender Breakdown</h3>
      <Table
        dataSource={genderList}
        columns={columns}
        pagination={false}
        size='small'
        bordered
      />
    </div>
  )
}
export default GenderTable
