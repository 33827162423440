import { Modal, Form, Button, Input } from 'antd'
import EditorDesc from './EditorDesc'
import { createFaq, createResource } from './services'

const AddFaqModal = ({ isModalVisible, setIsModalVisible, selectedType, succesAdd }) => {
  const handleOk = () => {
    setIsModalVisible(false)
  }
  const handleSubmit = async (values) => {
    const obj = {
      title: values.question,
      description: values.answer
    }
    const Data = selectedType == 'FAQ' ? await createFaq(values) : await createResource(obj)
    succesAdd()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <Modal
      title={selectedType == 'FAQ' ? `Add FAQ` : `Add Resource`}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className='add-modal'
      okText='Done'
      footer={false}
    >
      <Form
        onFinish={handleSubmit}
        className='login-form'
      >
        <div>
          <div className='login-label'>{selectedType == 'FAQ' ? 'Question' : 'Title'}</div>
          <Form.Item
            name={'question'}
            rules={[{ required: true, message: 'Please input your question' }]}
          >
            <Input
              className='login-input'
              placeholder={' '}
              type={'text'}
            />
          </Form.Item>
        </div>

        <div>
          <div className='login-label'>{selectedType == 'FAQ' ? 'Answer' : 'Description'}</div>
          <EditorDesc formName='answer' />
        </div>

        <Form.Item className='form-item-button'>
          <Button
            type='primary'
            htmlType='submit'
            className='button-login'
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default AddFaqModal
