import { serviceHandler } from '../../utils/serviceHandler'
import apiEndPoints from '../../utils/apiUrlConstants'

const { User } = apiEndPoints

export const getContacts = (page = 1, value = '', sort = 'desc') => {
  return serviceHandler({
    url: `contact_us`,
    methodType: 'get',

    params: {
      page: page,
      limit: 10,
      searchKey: 'message',
      searchValue: value,
      sortKey: 'created_on',
      sortOrder: sort ? sort : 'desc'
    }
  })
}
